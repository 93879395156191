
import { Box, CircularProgress, Grid } from '@material-ui/core';
import React, { FC } from 'react';

const Loader: FC<{size?: number|string}> = ({size = ''}) => {
    
    return (<Grid container color="secondary">
        <Grid item xs={12}>
            <Box m={3} display="flex" flexDirection="row" justifyContent="center">
                <CircularProgress color="secondary"/>
            </Box>
        </Grid>
        </Grid>)
}

export {Loader};