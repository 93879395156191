
import { Typography, Box, Grid, Divider } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from './Button';

const Nomatch = () => {

    const history = useHistory();

    const goToHome = useCallback(() => {
        history.replace("/app");
    }, [history]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
                    <Box fontSize="5rem" component="span" role="img" aria-label="lost"><span role="img" aria-label="triste">😵</span></Box>
                    <Typography variant="h5">
                        La pagina che stai cercando non è stata trovata
                    </Typography>
                </Box>
                <Divider/>
            </Grid>
            <Grid item xs={12}>
                <Button size="fluid" onClick={goToHome}>Torna alla HOME</Button>
            </Grid>
        </Grid>
    )
};

export {Nomatch}