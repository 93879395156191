

export enum EXAM_PERMISSION_ENUM {
    GENERIC_READ = 100, /* Lettura generico */
    GENERIC_WRITE = 200, /* Scrittura generico */
    MEDIA_READ = 300, /* Visionare immagini */
    MEDIA_COMMENT_READ = 320, /* Leggere i commenti alle immagini */
    MEDIA_COMMENT_WRITE = 330, /* Commentare immagini */
    MEDIA_UPLOAD = 350, /* Caricare immagini */
    REPORT_WRITE = 500, /* Stilare il referto */
    REPORT_READ = 510 /* Visionare il referto */
};

export enum EXAM_STATE {
    IN_EXECUTION = 10, 
    CLOSED = 75,
    ARCHIVED = 100
};

export enum ROLES {
    ADMIN = 1,
    OPERATOR = 2,
    SPECIALIST = 3,
    BASE_DOCTOR = 4
}