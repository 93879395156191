
import React, { useEffect, useState, useMemo } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { ExamCard } from '../exam/ExamCard';
import { Exam } from '../../../types/exam';
import { buildGetFetch } from '../../../services/base';
import { EXAM_STATE } from '../../../types/enums';
import { Loader } from '../../shared/Loader';

const Home = ({reload}) => {
    const [result, setResult] = useState<Array<Exam>>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        const [fetch] = buildGetFetch<Array<Exam>>(`/user/exam/status/${EXAM_STATE.IN_EXECUTION}`, {}, setResult, setLoading, setError);
        fetch();
    }, [reload]);

    const visitList = useMemo(() => {
        if(!result) return [];
        return result.map((v: any) => {
            return (
                <Grid item key={v.id} className="exam-item">
                    <Paper elevation={3}>
                        <ExamCard exam={v}></ExamCard>
                    </Paper>
                </Grid>
            );
        });
    }, [result]);

    if(loading || !result){
        return (<Loader/>)
    }

    if(error){
        return (<Grid container color="secondary">
        <Alert severity="error" variant="filled">
            <AlertTitle>Attenzione</AlertTitle>
            E' stato riscontrato un errore. Riprova più tardi</Alert>
        </Grid>)
    }

    return (
        <Grid container color="secondary" spacing={4}>
            {visitList}
            {visitList.length === 0 &&
                <Alert severity="warning" variant="filled">
                    <AlertTitle>Attenzione</AlertTitle>
                    Nessuna visita correntemente aperta
                </Alert>                    
            }
        </Grid>
    )
};

export {Home}