import React, { FC } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Body } from "../../shared/body/Body";
import { Examination } from "./Examination";
import { ExamPatient } from "./ExamPatient";
import { ExamReport } from "./ExamReport";
import { ExamSettings } from "./ExamSettings";


const ExamCenter: FC<{}> = () => {

    let { path } = useRouteMatch();
    
    return (
        <Switch>
            <Route exact path={path}>
                <Examination />
            </Route>
            <Route exact path={`${path}/report`}>
                <ExamReport/>
            </Route>
            <Route exact path={`${path}/body`}>
                <Body />
            </Route>
            <Route exact path={`${path}/patient`}>
                <ExamPatient />
            </Route>
            <Route exact path={`${path}/settings`}>
                <ExamSettings />
            </Route>
        </Switch>
    )
}

export {ExamCenter}