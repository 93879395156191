
import { Scene, Color, Group, PerspectiveCamera, WebGLRenderer, HemisphereLight, Raycaster, SphereGeometry, Mesh, MeshBasicMaterial, Vector3 } from 'three';
import { EventEmitter } from 'events';
import { BodyModel } from './models/bodymodel';
import { VisualPoint } from '../../types/visualpoint';
import { CacheLoader } from './CacheLoader';
import { OrbitControls } from '../../libs/orbit';

enum ThreeEvents { 
    POINTADD = ("POINT-ADD"), 
    POINTTOUCH = ("POINT-TOUCH"), 
    POINTREMOVE = ("POINT-REMOVE"), 
    MODELREADY = ("BODY-READY") };

export interface Point {
    uuid: string;
    x: number;
    y: number;
    z: number;
}

class ThreeBody extends EventEmitter {

    /**
     * margine di errore  in pixels (-/+)
     */
    static SLIDE_ERROR_MARGIN = 7;

    /**
     * Max points on the scene
     */
    static MAX_POINTS: number = 50;
    /**
     * Scene background color
     */
    static BACKGROUND = 0xe3ecff;
    /**
     * Current points on the model
     */
    private points: Array<Point> = [];
    /**
     * Group container for the points
     */
    private pointsGroup: Group = null;
    /**
     * Canvas where the scene is drawn
     */
    private canvas: HTMLCanvasElement = null;
    /**
     * Current scene camera
     */
    private camera: PerspectiveCamera = null;
       /**
     * Current scene controls
     */
    private controls: OrbitControls = null;   
    /**
    * Current scene
    */
    private scene: Scene = null;

    /**
     * cache of body models
     */
    private _bodyModels: {[key:string]:Group} = {}

    /**
     * The current model loaded on scene
     */
    private currentModel: BodyModel = null;

    /**
     * 
     * @param canvas 
     * @param defaultModel starting model to draw on scene
     * @param defaultPoints starting points to draw on scene
     */
    startGame(canvas: HTMLCanvasElement, defaultModel: BodyModel, defaultPoints: Array<VisualPoint> = []) {
        console.log(defaultModel);
        this.canvas = canvas;

        const height = window.innerHeight;
        const width = window.innerWidth;
        const fov = 75;

        this.scene = new Scene();
        this.scene.background = new Color(0xe3ecff);
        this.camera = new PerspectiveCamera(fov, width / height, 0.1, 100);

        this.pointsGroup = new Group();
        this.pointsGroup.name = "POINTS_SPHERE_GROUP";

        const renderer = new WebGLRenderer({
            canvas: this.canvas,
            antialias: true
        });
        renderer.setSize(width, height);

        const light = new HemisphereLight(0xfffcf2, 0x3D3D3D, 1.2);
        this.scene.add(light);

        const raycaster = new Raycaster();
        this.controls = new OrbitControls(this.camera, this.canvas);

        this.controls.enableKeys = false;
        this.controls.enablePan = false;
        this.controls.maxDistance = 11;
        this.controls.minDistance = 3;
        this.camera.position.x = 0;
        this.camera.position.z = 7;
        this.camera.position.y = 1;
        this.controls.update();

        let init = false;


        const animate = () => {
            requestAnimationFrame(animate);
            renderer.render(this.scene, this.camera);
            if (!init && this.getCurrentModel() != null) {
                init = true;
                if (defaultPoints.length > 0) {
                    try {
                        for (let p of defaultPoints) {
                            //console.log(p);
                            this.addPoint({
                                x: p.vector.x,
                                y: p.vector.y,
                                z: p.vector.z,
                                uuid: p.uuid
                            });
                        }
                    } catch (e) {
                        console.warn("Invalid points found");
                    }
                }
                console.warn("Adding points group to the scene");
                this.scene.add(this.pointsGroup);
            }
        };

        animate();

        /**
         *  -- To be updated --
         */
        this.canvas.addEventListener('mousedown', (event) => {
            let body = this.getCurrentModel();
            if (body != null) {
                const canvasBounds = canvas.getBoundingClientRect();
                const mouseVector = new Vector3();
                mouseVector.x = ((event.clientX - canvasBounds.left) / canvas.clientWidth) * 2 - 1;
                mouseVector.y = - ((event.clientY - canvasBounds.top) / canvas.clientHeight) * 2 + 1;
                //update raycast on mouse-camera
                raycaster.setFromCamera(mouseVector, this.camera);
                //check intersections
                const intersects = raycaster.intersectObjects(this.scene.children, true);
                if (intersects.length > 0) {
                    for (let intersection of intersects) {
                        const { point } = intersection;
                        this.addPoint(point);
                        this.emit(ThreeEvents.POINTADD.toString(), point);
                    }
                }
            }
        }, false);
        const currentTouches = [];

        this.canvas.addEventListener('touchend', (event) => {
            event.preventDefault();
            let body = this.getCurrentModel();
            if (body != null && event.changedTouches.length === 1) {
                const canvasBounds = canvas.getBoundingClientRect();
                const endTouch = event.changedTouches[0];
                //lookup for the corrisponding single start touch
                const index = currentTouches.findIndex((o) => o.identifier === endTouch.identifier);
                if (index >= 0) {
                    const startTouch = currentTouches[index];
                    //check if the start touch is on the same position (user did not drag/slide/ecc)
                    if ( 
                        ((startTouch.pageX - ThreeBody.SLIDE_ERROR_MARGIN) <= endTouch.pageX && endTouch.pageX <= (startTouch.pageX + ThreeBody.SLIDE_ERROR_MARGIN)) && 
                        ((startTouch.pageY - ThreeBody.SLIDE_ERROR_MARGIN) <= endTouch.pageY && endTouch.pageY <= (startTouch.pageY + ThreeBody.SLIDE_ERROR_MARGIN)) 
                       ) {
                        console.warn("Simple touch. Valid");
                        const mouseVector = new Vector3();
                        mouseVector.x = ((startTouch.clientX - canvasBounds.left) / canvas.clientWidth) * 2 - 1;
                        mouseVector.y = - ((startTouch.clientY - canvasBounds.top) / canvas.clientHeight) * 2 + 1;
                        //update raycast on mouse-camera
                        raycaster.setFromCamera(mouseVector, this.camera);
                        //check intersections
                        const intersects = raycaster.intersectObjects(this.scene.children, true);
                        if (intersects.length > 0) {
                            console.warn(`${intersects.length} intersections`);
                            // i'm interested only on the top intersection
                            const firstIntersection = intersects[0];
                            const { point, object } = firstIntersection;
                            const { uuid } = object.parent;
                            if (uuid === body.uuid) {
                                //body intersection
                                console.warn("Touched body");
                                this.addPoint(point);
                                this.emit(ThreeEvents.POINTADD.toString(), point);
                            } else if (uuid === this.pointsGroup.uuid) {
                                // possible point intersection
                                console.warn(`intersection: ${object.uuid}`);
                                const pointTouchIndex = this.points.findIndex((pg) => pg.uuid === object.uuid);
                                if (pointTouchIndex >= 0) {
                                    this.emit(ThreeEvents.POINTTOUCH.toString(), this.points[pointTouchIndex]);
                                    // this.removePoint(object.uuid);
                                } else {
                                    console.error("Should never happen");
                                }
                            }
                            
                            /*for (let intersection of intersects) {
                                console.warn(intersection);
                                const { point, object } = intersection;
                                const { uuid } = object.parent;
                                if (uuid === body.uuid && intersects.length >= 1) {
                                    console.warn("Touched body");
                                    this.addPoint(point);
                                    this.emit(ThreeEvents.POINTADD.toString(), point);
                                } else if (uuid === this.pointsGroup.uuid && intersects.length >= 2) {
                                    console.warn(`intersection: ${object.uuid}`);
                                    const pointTouchIndex = this.points.findIndex((pg) => pg.uuid === object.uuid);
                                    if (pointTouchIndex >= 0) {
                                        this.emit(ThreeEvents.POINTTOUCH.toString(), this.points[pointTouchIndex]);
                                        // this.removePoint(object.uuid);
                                    } else {
                                        console.error("Should never happen");
                                    }
                                }
                            }*/
                        } else {
                            console.warn("No intersection with body");
                        }
                    } else {
                        console.warn("Sliding. Invalid");
                    }
                    currentTouches.splice(index, 1);
                }
            }
        }, false);

        this.canvas.addEventListener('touchstart', (event) => {
            const touches = event.changedTouches;
            for (let t of touches) {
                currentTouches.push({
                    identifier: t.identifier,
                    pageX: t.pageX,
                    pageY: t.pageY,
                    clientX: t.clientX,
                    clientY: t.clientY
                });
            }
        })

        /**
         * Start loading the default model
         */
        this.loadModel(defaultModel);

    }

    /**
     * Cleanup the scene to free memory. Should be called when a component unmounts
     */
    endGame() {
        /**
         * Remove model from the scene
         */
        if(this.currentModel){
            this.scene.remove(this._bodyModels[this.currentModel.name]);
            this.scene.remove(this.pointsGroup);
        }
        /**
         * Dispose all cached models
         */
        Object.keys(this._bodyModels).forEach((k: string) => {
            const group = this._bodyModels[k];
            if(group.children){
                group.children.forEach((v: Mesh) => {
                    v.geometry.dispose();
                    // @ts-ignore
                    v.material.dispose();
                })
            }
        });
        if(this.pointsGroup && this.pointsGroup.children){
            this.pointsGroup.children.forEach((v: Mesh) => {
                v.geometry.dispose();
                // @ts-ignore
                v.material.dispose();
            })
        }
        /**
         * Disponse scene
         */
        this.scene.dispose();
    }

    /**
     * Load and cache a model
     * @param model 
     */
    private async loadModel(model: BodyModel) {
        if(this.currentModel && model.name === this.currentModel.name){
            return;
        }
        if (this._bodyModels[model.name]) {
            console.warn("Using cached model");
            this.showModelOnScene(model);
        } else {
            console.warn(`Loading model ${process.env.PUBLIC_URL}/${model.file}`);

            const object = await CacheLoader.getModel(model).catch(() => {
                console.error("Error loading model");
                return null;
            });
            console.log("Loaded");
            if(object !== null){
                this._bodyModels[model.name] = object;
                this.showModelOnScene(model);
            }

        }
    }

    /**
     * Get current model
     */
    private getCurrentModel(){
        return (this.currentModel !== null) ? this._bodyModels[this.currentModel.name] : null;
    }

    /**
     * Show a model on the scene, optionally switching 
     * @param model 
     */
    private showModelOnScene(model: BodyModel) {
        if(this.currentModel !== null){
            //remove from scene previous model
            this.scene.remove(this._bodyModels[this.currentModel.name]);
        }
        this.currentModel = model;
        const obj = this._bodyModels[this.currentModel.name];
        const { scale, position, rotation } = this.currentModel;
        obj.traverse(function (child) {
            if (child instanceof Mesh) {
                child.material.color.setHex(model.skin);
                //we need this to understand when we touch the body mesh
                //child.geometry.computeBoundingBox();
                //child.geometry.boundingBox;
            }

        });
        this.scene.add(obj);
        if (position) {
            if (position.x !== undefined) {
                obj.position.x = position.x;
            }
            if (position.y !== undefined) {
                obj.position.y = position.y;
            }
            if (position.z !== undefined) {
                obj.position.z = position.z;
            }
        }
        if (rotation) {
            if (rotation.x !== undefined) {
                obj.rotateX(rotation.x);
            }
            if (rotation.y !== undefined) {
                obj.rotateX(rotation.y);
            }
            if (rotation.z !== undefined) {
                obj.rotateX(rotation.z);
            }
        }
        obj.scale.set(scale.x, scale.y, scale.z);
        obj.name = 'BODY_MODEL_OBJ';
        this.emit(ThreeEvents.MODELREADY.toString(), "");
    }

    changeModel(model: BodyModel){
        this.loadModel(model);
    }

    resetPoints() {
        for (let p of this.points) {
            const point: any = this.pointsGroup.getObjectByProperty('uuid', p.uuid);
            point.geometry.dispose();
            point.material.dispose();
            this.pointsGroup.remove(point);
        }
        this.points.length = 0;
        this.saveState();
    }

    resetPosition() {
        const { camera, controls } = this;
        camera.position.x = 0;
        camera.position.z = 7;
        camera.position.y = 1;
        controls.update();
    }

    addPoint(point: any) {
        if (this.points.length < ThreeBody.MAX_POINTS) {
            this.points.push(point);
            const geometry = new SphereGeometry(0.1, 10, 10);
            const material = new MeshBasicMaterial({ color: 0x8a0000 });
            const mesh = new Mesh(geometry, material);
            mesh.scale.set(0.7, 0.7, 0.7);
            mesh.position.x = point.x;
            mesh.position.y = point.y;
            mesh.position.z = point.z;
            if(point.uuid){
                //if I have an uuid I use that
                mesh.uuid = point.uuid;
            } else {
                //I use the generated mesh UUID
                point.uuid = mesh.uuid;
            }
            this.pointsGroup.add(mesh);
            this.saveState();
        } else {
            console.warn("Max points reached")
        }
    }

    removePoint(uuid: string) {
        const point: any = this.pointsGroup.getObjectByProperty('uuid', uuid);
        if (point) {
            point.geometry.dispose();
            point.material.dispose();
            this.pointsGroup.remove(point);
            const stateIndex = this.points.findIndex(p => p.uuid === uuid);
            this.points.splice(stateIndex, 1);
            this.saveState();
            console.warn(`Point removed ${uuid}`);
        } else {
            console.warn("Point not found");
        }
    }

    private saveState() {
        //console.log(this.points);
        //localStorage.setItem('points', JSON.stringify(this.points));
    }

}

export { ThreeBody, ThreeEvents }