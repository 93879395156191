
import { SnackbarProvider } from "notistack"
import React, { FC, Fragment, useEffect, useState } from "react"
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
import App from "./App"
import { Nomatch } from "../shared/Nomatch"
import { Login } from "./login/Login"
import { Auth } from "../../services/auth"

const Main: FC<{}> = () => {
    return (
      <BrowserRouter>
        <SnackbarProvider maxSnack={3}>
          <Switch>
            <Route exact path="/">
              <Login />
            </Route>
            <ProtectedRoute>
              <App />
            </ProtectedRoute>
            <Route path="*">
              <Nomatch />
            </Route>
          </Switch>
        </SnackbarProvider>
      </BrowserRouter>
    )
}

const ProtectedRoute: FC<{}> = ({children}) => {
  return <Route path="/app" render={({location}) => {
    return Auth.isLoggedIn() ? children : <SyncRoute>{children}</SyncRoute>
  }}>
    
  </Route>
}

const SyncRoute: FC<{}> = ({children}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState<boolean>(false);
  useEffect(() => {
    const [sync] = Auth.sync(setSuccess, setLoading, setError);
    sync();
  }, []);
  if(loading){
    return <div className="sync-container">
      <h5>Sincronizzazione utente, attendere...</h5>
      </div>
  }
  if(error || !success){
    return <Redirect to={{pathname: '/'}}></Redirect>;
  }
  return <Fragment>{children}</Fragment>;
}


export {Main}