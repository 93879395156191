
import Axios, { Canceler } from 'axios';
import { API_ROUTE, BASE, buildGetFetch } from './base';
import {User} from '../types/user';
import { NotificationService } from './notificationservice';
import { ROLES } from '../types/enums';
import { Patient } from '../types/patient';
import { APP_MODE } from '../utils/versioning';

interface LoginUserData {
    user: User;
    invites: number;
    roles: Array<{
        role_id: ROLES;
    }>;
}
interface LoginPatientData {
    email: string;
    patient: Patient;
    id: number;
}


interface IAuth {
    /**
     * Start application
     */
    start: () => Promise<any>;
    login: (email: string, password: string) => Promise<any>;
    logout: () => void;
    user: () => User | null;
    sync: (onSync: (success: boolean) => void, onLoad: (load: boolean) => void, onError: (load: boolean) => void) => [() => Promise<LoginUserData | LoginPatientData | null>, Canceler];
    isLoggedIn: () => boolean;
    csrf: () => string;
    canBeInvited: () => boolean;
    isAdmin: () => boolean;
    isOperator: () => boolean;
    isSpecialist: () => boolean;
    isBaseDoctor: () => boolean;
    consultation: () => any | null;
    setConsultation: (c:any) => void;
}

/**
 * TODO quando si aggiorna il token va aggiornato anche in Echo -> Echo.connector.pusher.config.auth.headers['X-CSRF-TOKEN'] = token
 * https://github.com/laravel/echo/issues/252
 */

export const Auth: IAuth = (() => {
    
    Axios.defaults.withCredentials = true;
    Axios.defaults.baseURL = BASE;

    /**
     * Private logged in variable
     */
    let _isLoggedIn = false;
    /**
     * Private user variable
     */
    let _user: User | null;
    /**
     * could be useful
     */
    let _csrf_token: string = '';    

    let _roles: Array<{
        role_id: ROLES;
    }> = [];

    /**
     * Consultation settings (by now, available only for patients)
     */
    let _consultation:any; 

    const setupConsultation = (c:any) => {
        _consultation=c;
    }
    
    const setupLogin = (data: LoginUserData | LoginPatientData) => {
        if(_isLoggedIn === false && !_user){
            if(APP_MODE === 'user'){
                const _d: LoginUserData = data as LoginUserData;
                _user = _d.user;
                _roles = _d.roles;
            } else {
                const _d: LoginPatientData = data as LoginPatientData;
                //console.log("d",_d);
                _user = {
                    id: _d.patient.id,
                    email: _d.email,
                    name: _d.patient.name,
                    surname: _d.patient.surname
                };
                _roles = [];
            }
            _isLoggedIn = true;
            NotificationService.connect(_user.id);
        }
    }

    const setupLogout = () => {
        if(_isLoggedIn === true && _user){
            _user = null;
            _roles = [];
            _isLoggedIn = false;
            NotificationService.disconnect();
        }
    }

    return {
        csrf: () => _csrf_token,
        start: () => {
            return new Promise(async (resolve, reject) => {
                const result = await Axios.get(`/sanctum/csrf-cookie`).catch((reason: any) => {
                    reject(reason);
                    return null;
                });
                if(result){
                    _csrf_token = result.config.headers['X-XSRF-TOKEN'];
                    resolve();
                }
            });
        },
        login: (email: string, password: string) => {
            return new Promise(async (resolve, reject) => {
                const result = await Axios.post<LoginUserData | LoginPatientData>(`${API_ROUTE}/login`, {email, password}).catch(() => {
                    reject();
                    return null;
                });
                if(result && result.data){
                    setupLogin(result.data);
                    resolve();
                }
            });
        },
        sync: (onSync: (success: boolean) => void, onLoad: (load: boolean) => void, onError: (load: boolean) => void) => {
            const _sync = (user: LoginUserData | LoginPatientData | null) => {
                if(user){
                    console.warn("User is authenticated");
                    setupLogin(user);
                    onSync(true);
                } else {
                    console.warn("User is not authenticated");
                    setupLogout();
                    onSync(false);
                }
            }
            return buildGetFetch<LoginUserData | LoginPatientData>('/user', {}, _sync, onLoad, onError);
        },
        logout: () => {
            return new Promise(async (resolve, reject) => {
                await Axios.post(`${API_ROUTE}/logout`, {});
                setupLogout();
                resolve();
            });
        },
        user: (): User | null => {
            return _user;
        },
        isLoggedIn: () => {
            return _isLoggedIn;
        },
        canBeInvited: () => {
            return _roles.findIndex((r) => r.role_id === ROLES.ADMIN || r.role_id === ROLES.SPECIALIST) >= 0;
        },
        isAdmin: () => {
            if(_roles.findIndex((r) => r.role_id === ROLES.ADMIN) >= 0) return true; // amministratore
            return false;
        },
        isSpecialist: () => {
            if(_roles.findIndex((r) => r.role_id === ROLES.SPECIALIST) >= 0) return true; // specialista
            return false;
        },
        isOperator: () => {
            if(_roles.findIndex((r) => r.role_id === ROLES.OPERATOR) >= 0) return true; // tecnico sanitario
            return false;
        },
        isBaseDoctor: () => {
            if(_roles.findIndex((r) => r.role_id === ROLES.BASE_DOCTOR) >= 0) return true; // medico di base
            return false;
        },
        consultation: () => {
            return _consultation;
        },
        setConsultation: (c:any) => {
            setupConsultation(c);
        }
    }

})();

