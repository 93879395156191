
import { Box, createStyles, Divider, Fab, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_ROUTE, BASE, buildGetFetch } from "../../../services/base";
import { Report } from "../../../types/report";
import { formatDT } from "../../../utils/dateutils";
import { Back } from "../../shared/Back";
import { Loader } from "../../shared/Loader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: 'fixed',
      bottom: theme.spacing(12),
      right: theme.spacing(4),
    },
    text: {
        textAlign: 'justify'
    }
  }),
);

const ExamReport: FC<{}> = () => {

    const classes = useStyles();
    const [report, setReport] = useState<Report>();
    const [loading, setLoading] = useState<boolean>(true);
    
    const { examId } = useParams<{examId: any}>();

    useEffect(() => {
        const [fetch, cancel] = buildGetFetch(`/exam/${examId}/report`, {}, setReport, setLoading);
        fetch();
        return () => {
            cancel();
        };
    }, [examId]);

    if(loading){
        return (<Loader/>)
    }

    return (<div>
        <Back/>
        <div style={{marginTop:'10px'}}>
            {report ? 
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Typography variant="h6" color="secondary">Parere medico</Typography>
                            <Fab className={classes.fab} aria-label="Scarica parere medico" size="small" color="secondary" component="a" href={`${BASE}${API_ROUTE}/exam/${examId}/report/download`} download={`parere_${examId}.pdf`} >
                                <DownloadIcon/>
                            </Fab>
                        </Box>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12}>
                        Data parere medico {formatDT(report.release_ts)}
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" flexDirection="column">
                            <Box color="textSecondary" component="span">Parere medico</Box>
                            <Typography className={classes.text}>{report.report}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" flexDirection="column">
                            <Box color="textSecondary" component="span">Raccomandazioni / Cura</Box>
                            <Typography className={classes.text}>{report.recommendation}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" flexDirection="column">
                            <Box color="textSecondary" component="span">Annotazioni</Box>
                            <Typography className={classes.text}>{report.reminder}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            : <Grid container color="secondary">
                <Grid item xs={12}>
                    <Alert severity="warning" variant="filled" style={{width: '100%'}}>
                    <AlertTitle>Attenzione</AlertTitle>
                    Il parere medico per questa visita non è ancora disponibile.<br/><br/><i>Controlla più tardi</i></Alert>
                </Grid>
            </Grid>}
        </div>
    </div>)
}


export {ExamReport}