import React from 'react';

const TestWebcam = ({ onCapture }) => {
  return (
    <>
      <input id="input_file_photo"
        type="file"
        accept="image/*"
        capture="environment"
        onChange={(event) => {
          onCapture(event.target.files[0]);
        }}
      ></input> Scatta qui
      )
    </>
  );
};

export default TestWebcam;
