import { createContext } from "react";

type TabContextType = {
    active: number;
    changeTab: (tab: number) => void;
  }
  
  const TabContext = createContext<TabContextType>({
    active: -1,
    changeTab: () => {}
  });

  export {TabContext};