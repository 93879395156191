
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import React from 'react';
import { Body } from '../../shared/body/Body';
import { Examination } from './Examination';
import { ExamsList } from './ExamsList';
import { ExamReport } from './ExamReport';
import PaymentCenter from '../payment/PaymentCenter';
import { PaymentSuccess } from '../payment/PaymentSuccess';
import { PaymentRejected } from '../payment/PaymentRejected';
import { Chat } from '../chat/Chat';
import { Anamnesis } from './Anamnesis';
import { ContactForm } from './ContactForm';


function ExamCenter() {
    let { path } = useRouteMatch();
    return (
        <React.Fragment>
            <Switch>
                <Route exact path={path}>
                    <ExamsList />
                </Route>
                <Route exact path={`${path}/:examId`}>
                    <Examination />
                </Route>
                <Route path={`${path}/:examId/body`}>
                    <Body />
                </Route>
                <Route path={`${path}/:examId/report`}>
                    <ExamReport />
                </Route>
                <Route path={`${path}/:examId/anamnesis`}>
                    <Anamnesis />
                </Route>
                <Route path={`${path}/:examId/chat`}>
                    <Chat/>
                </Route>
                <Route path={`${path}/:examId/contact`}>
                    <ContactForm/>
                </Route>
                <Route path={`${path}/:examId/payment`}>
                    <PaymentCenter/>
                </Route>
                <Route path={`${path}/:examId/:paymentId/payment-success`}>
                    <PaymentSuccess/>
                </Route>
                <Route path={`${path}/:examId/:paymentId/payment-reject`}>
                    <PaymentRejected/>
                </Route>
            </Switch>
        </React.Fragment>
    );
}


export default ExamCenter;

