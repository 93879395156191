
import React, { useCallback, Fragment, useState, useEffect } from 'react';
import { Button } from '../../shared/Button';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Typography, Grid, Box, Chip, Divider } from '@material-ui/core';
import { fakeId } from '../../../utils/faker';
import { Back } from '../../shared/Back';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { buildGetFetch } from '../../../services/base';
import { Auth } from '../../../services/auth';

const Examination = () => {
    let { examId } = useParams<{examId: any}>();
    const [num, setNum] = useState();
    let history = useHistory();
    let location = useLocation();

    useEffect(() => {
        const [fetch, cancel] = buildGetFetch<any>(`/user/support`, {}, (data) => {
            if(data){
                setNum(data.phone_number);
            }
        });
        fetch();
        return () => {
            cancel();
        }
    }, [])

    const goToBody = useCallback((path: string) => {
        history.push(`${location.pathname}/${path}`);
    }, [history,location.pathname]);

    const isAdmin:boolean=Auth.isAdmin();
    const isSpecialist:boolean=Auth.isSpecialist();
    const isBaseDoctor:boolean=Auth.isBaseDoctor();
    
    const TEXT = "Aiuto";
    return (
        <Fragment>
            <Back goBackTo={() => {history.replace("/app")}}/>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" flexDirection="row" mb={1}>
                        <Typography variant="h6" color="secondary">Visita </Typography>
                        <Chip color="primary" variant="outlined" label={fakeId(examId)}/>
                    </Box>
                    <Divider/>
                </Grid>
                <Grid item xs={6}>
                    <Box borderRadius={'3px'} color="primary.contrastText" style={{height: '100px'}}>
                        <Button size="fluid" onClick={() => {goToBody('patient')}}>
                            <Typography>Paziente</Typography>
                            <i className="fa fa-user-injured"/>
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box borderRadius={'3px'} color="primary.contrastText" style={{height: '100px'}}>
                        <Button size="fluid" onClick={() => {goToBody('body')}}>
                            <Typography>Immagini</Typography>
                            <i className="fa fa-camera"/>
                        </Button>
                    </Box>
                </Grid>
                { (isAdmin || isSpecialist || isBaseDoctor) &&
                    <Grid item xs={6}>
                        <Box borderRadius={'3px'} color="primary.contrastText"style={{height: '100px'}}>
                            <Button size="fluid" onClick={() => {goToBody('report')}}>
                                <Typography>Parere medico</Typography>
                                <i className="fa fa-file"/>
                            </Button>
                        </Box>
                    </Grid>
                }
                { (isAdmin || isSpecialist || isBaseDoctor) &&
                    <Grid item xs={6}>
                        <Box borderRadius={'3px'} color="primary.contrastText" style={{height: '100px'}}>
                            <Button size="fluid" onClick={() => {goToBody('settings')}}>
                                <Typography>Richiamo</Typography>
                                <i className="fa fa-calendar-check"/>
                                </Button>
                        </Box>
                    </Grid>
                }
                {num && 
                <Grid item xs={12}>
                    <Box borderRadius={'3px'} style={{height: '60px'}} alignItems="center" display="flex" flexDirection="column">
                        <Typography>Supporto</Typography>
                        <a href={`https://wa.me/${num}?text=${TEXT}`}>
                            <WhatsAppIcon fontSize="large" style={{fill: "#4fce5d"}}/>
                        </a>
                    </Box>
                </Grid>
                }
            </Grid>
        </Fragment>
    )
};

export {Examination}