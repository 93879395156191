
import React from 'react';
import { Container } from '@material-ui/core';

const Noapp = () => {
    return (
        <Container>
            <p>Controlla la connessione</p>
        </Container>
    )
};

export {Noapp}