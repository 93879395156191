import { ExamVisualModel } from "../../../types/examvisualmodel"
import { Female } from "./female"
import { Male } from "./male";

export enum SKIN_TYPES {
    F1 = ("0xFFDFC4"),
    F2 = ("0xf6d0b1"),
    F3 = ("0xe8b68f"),
    F4 = ("0xd29f7c"),
    F56 = ("0x3b1f1b")
};

export type BodyModel = {
    name: string;
    file: string;
    skin: SKIN_TYPES;
    scale: {
        x: number;
        y: number;
        z: number;
    }
    position?: {
        x?: number;
        y?: number;
        z?: number;
    },
    rotation?: {
        x?: number;
        y?: number;
        z?: number;
    }
}

export const ModelFactory = {
    createFromVisualModel: (vm: ExamVisualModel) => {
        if(vm.model_id === 1){
            return new Male(vm.model_skin);
        } else {
            return new Female(vm.model_skin);
        }
    }
}