
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import React, { useState, useEffect, useCallback } from 'react';
import { Home } from './home/Home';
import { Nomatch } from '../shared/Nomatch';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { Menu } from './menu/Menu';
import { Paper } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useNotification } from '../../utils/hooks';
import { USER_CHANNEL } from '../../services/notificationservice';
import { ExamClosure, ExamCreation } from '../../types/examevents';
import { ExamCenter } from './exam/ExamCenter';
import { Auth } from '../../services/auth';
import { AppMenu } from '../shared/AppMenu';
import { AppMenuChoices } from './appmenu/AppMenuChoices';
import { Loop } from '@material-ui/icons';

function App() {
  const [reload, setReload] = useState(false);
  const [open, setOpen] = useState(false);
  const { path } = useRouteMatch();
  const history = useHistory();
  const {enqueueSnackbar} = useSnackbar();
  const [version, setVersion] = useState<string>();
  const [newVersionAvailable, setNewVersionAvailable] = useState<boolean>(false);
  
  useNotification(USER_CHANNEL.events.ExamClosure, (e: ExamClosure) => {
    enqueueSnackbar("La visita corrente è stata chiusa", {variant: "warning"});
    history.replace('/app');
    setReload((prev) => !prev);
  });
  
  useNotification(USER_CHANNEL.events.ExamCreation, (e: ExamCreation) => {
    enqueueSnackbar("Nuova visita aperta", {variant: "success"});
    history.replace('/app');
    setReload((prev) => !prev);
  });

  useEffect(() => {
    let savedAppInfoAsString=localStorage.getItem("app-info");
    let savedAppInfo=null;
    if(savedAppInfoAsString) {
        try {
            savedAppInfo=JSON.parse(savedAppInfoAsString);
        } catch(e) {
            savedAppInfo=null;
        }
    }
    if(savedAppInfo && savedAppInfo.version) {
      console.log("savedAppInfo",savedAppInfo)
      setVersion(savedAppInfo.version);
      checkNewVersionAvailable(savedAppInfo.version);
    } else {
      setVersion("[no version]");
    }
  },[]);

  const checkNewVersionAvailable = (vv:string) => {
    if(!vv) {
      console.log("No saved version, considering no update available");
      return;
    }
    var myHeaders = new Headers();
        myHeaders.append('pragma', 'no-cache');
        myHeaders.append('cache-control', 'no-cache');
    var myInit = {
        method: 'GET',
        headers: myHeaders,
        };
    fetch('/app-info.json',myInit)
      .then((response) => response.json())
      .then((serverInfo) => {
          console.log("Saved version present, comparing versions",vv,serverInfo.version);
          if(vv!==serverInfo.version) {
              console.log("Version mismatch, an update is available....");
              setNewVersionAvailable(true);
          }
    });
  }

  const updateApp = useCallback(() => {
      window.location.href="/";
  },[]);

  const isOnBody=(window.location.href.endsWith("/body"));
  const hcss=(isOnBody)?"header-in-body":"";

  return (
    <React.Fragment>
        <SwipeableDrawer anchor="left" open={open} onOpen={() => { setOpen(true); }} onClose={() => { setOpen(false); }}>
          <Menu onClick={() => {setOpen(false)}}></Menu>
        </SwipeableDrawer>
        <header className={hcss}>
          <AppMenu />
          <h1>DermaCo</h1>
          <small>{Auth.user().name} {Auth.user().surname}</small>
        </header>
        <main>
          <div className="container">
            { newVersionAvailable &&
              <div className="update-available" onClick={updateApp}>                
                <div><Loop fontSize="large" /></div>
                E' disponibile un aggiornamento software. Premi per aggiornare
              </div>
            }
            <Paper elevation={0}>
              <Switch>
                <Route exact path={path}>
                  <Home reload={reload}/>
                </Route>
                <Route path={`${path}/exam/:examId`}>
                  <ExamCenter />
                </Route>
                <Route path={`${path}/appmenu`}>
                  <AppMenuChoices />
                </Route>
                <Route path="*">
                  <Nomatch />
                </Route>
              </Switch>
            </Paper>              
          </div>
        </main>
        <footer></footer>
    </React.Fragment>
  );
}

export default App;

