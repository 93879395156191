
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { LinearProgress, Grid, makeStyles, Theme, createStyles, Paper, Typography, Box } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Auth } from '../../../services/auth';
import { Button } from '../../shared/Button';
import { useSnackbar } from 'notistack';
import { APP_MODE } from '../../../utils/versioning';
import { buildPostFetch } from '../../../services/base';
import { Visibility } from '@material-ui/icons';

interface Values {
    email: string;
    password: string;
}

interface ResetValues {
    email: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
        backgroundColor: theme.palette.primary.main,
        overflow: 'auto',
        height: '100%'
    },
    grid: {
      marginTop: theme.spacing(10),
    },
    login: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.primary,
    }
  }),
);

const Login = () => {
    const classes = useStyles();
    const [isBootstrapping,setBootstrapping]=useState(false);
    const [bootstrapPerformed,setBootstrapPerformed]=useState(false);
    const [isResetting,setResetting]=useState(false);
    const [version,setVersion]=useState("(...)");
    const [lastCheckLoginDate,setLastCheckLoginDate] = useState<Date>();
    const {enqueueSnackbar} = useSnackbar();
    let history = useHistory();    
    
    const performAuthentication = useCallback((values: Values, actions) => {
        if(!window.navigator.onLine) {
            enqueueSnackbar("Rete non disponibile", {
                variant: "warning"
            });
            actions.setSubmitting(false);
            setBootstrapPerformed(true);
            return;
        }

        Auth.login(values.email, values.password).then(() => {
            localStorage.setItem("credentials",JSON.stringify(values));
            history.replace('/app');
        }).catch(() => {
            enqueueSnackbar("Credenziali non valide", {
                variant: "error"
            });
            actions.setSubmitting(false);
            setBootstrapPerformed(true);
        })
    },[enqueueSnackbar,history]);

    const zapCacheAndReload = () => {
        try {
        if (caches) {
            caches.keys().then(function(names) {
              for (let name of names) caches.delete(name);
            });
          }
        } catch(e) {}
        window.location.reload(true);
    }
    
    const checkSavedCredentials = () => {
        // Evitiamo loop - es. in caso di errore di rete
        const now:Date=new Date();
        if(lastCheckLoginDate) {
            const elapsed:number=now.getTime()-lastCheckLoginDate.getTime();
            console.log("Elapsed :" + elapsed);
            if(elapsed<5*1000) {
                console.log("Breaking check saved credential loop");
                setBootstrapping(false);
                setBootstrapPerformed(true);
                return;
            }
        }

        // Verifichiamo eventuali credenziali salvate
        let savedCredentials=null;
        let savedCredentialsAsString=localStorage.getItem("credentials");
        if(savedCredentialsAsString) {
            savedCredentials=JSON.parse(savedCredentialsAsString); 
            console.log("Trying saved credentials...",savedCredentials);   
            setLastCheckLoginDate(now);
            performAuthentication(savedCredentials,{ setSubmitting: setBootstrapping });
        } else {
            setBootstrapping(false);
            setBootstrapPerformed(true);
        }
    }

    useEffect(() => {
//        console.log("---- useEffect",isBootstrapping,bootstrapPerformed);
        if(isBootstrapping) return;
        if(bootstrapPerformed) return;
        setBootstrapping(true);

        // verifichiamo la versione applicativa
        var myHeaders = new Headers();
            myHeaders.append('pragma', 'no-cache');
            myHeaders.append('cache-control', 'no-cache');
        var myInit = {
            method: 'GET',
            headers: myHeaders,
        };
        fetch('/app-info.json',myInit)
            .then((response) => response.json())
            .then((serverInfo) => {
                let reloading=false;
                console.log("server app-info",serverInfo);
                let savedAppInfoAsString=localStorage.getItem("app-info");
                let savedAppInfo=null;
                if(savedAppInfoAsString) {
                    try {
                        savedAppInfo=JSON.parse(savedAppInfoAsString);
                    } catch(e) {
                        savedAppInfo=null;
                    }
                }
                if(!savedAppInfo || !savedAppInfo.version) {
                    console.log("No saved version, storing the server app-info");
                    localStorage.setItem("app-info",JSON.stringify(serverInfo));
                    setVersion(serverInfo.version);
                } else {
                    console.log("Saved version present, comparing versions",savedAppInfo.version,serverInfo.version);
                    if(savedAppInfo.version!==serverInfo.version) {
                        console.log("Version mismatch, reloading....");
                        localStorage.removeItem("app-info");
                        zapCacheAndReload();
                        reloading=true;
                    } else {
                        setVersion(savedAppInfo.version);
                    }
                }
                if(!reloading) {
                    checkSavedCredentials();
                }
            });
        });

    const onSubmit = useCallback((values: Values, actions) => {
        performAuthentication(values,actions);
    }, [performAuthentication]);

    const onResetSubmit = useCallback((values: ResetValues, actions) => {
        let email=values.email;
        if (email && email !== '') {
            let pars={
                email: email
            }
            const [call] = buildPostFetch<any>("/resetPassword",pars);
            call().then((v) => {
                if(v) {
                    enqueueSnackbar("Credenziali inviate. Controlla la tua email", {
                        variant: "success"
                    });
                    setResetting(false);
                } else {
                    enqueueSnackbar("Errore - controlla l'indirizzo fornito", {
                        variant: "error"
                    });
                    setResetting(false);
                }
            });
        } else {
            enqueueSnackbar("Specifica indirizzo email", {
                variant: "error"
            });
        }
    }, [enqueueSnackbar]);

    const togglePassword=useCallback(() => {
        let pt:any = document.getElementsByName("password");
        if(pt[0].type=="text") pt[0].type="password";
        else pt[0].type="text";
    },[]);      

    return (
        <div className={classes.wrapper}>
            <Grid container direction="column" justify="center" alignItems="center" className={classes.grid}>
                <Typography variant="h4">DermaCo</Typography>
                {isBootstrapping && 
                    <Box mt={2} mb={2}>
                        <LinearProgress color="secondary" />
                    </Box>
                }
                {!isBootstrapping && !isResetting &&
                    <Box m={3} className="login-container">
                        <Paper elevation={3} className={classes.login}>
                            <Formik
                                initialValues={{
                                    email: '',
                                    password: '',
                                }}
                                validate={values => {
                                    const errors: Partial<Values> = {};
                                    if (!values.email || values.email.indexOf('@')<0 ||  values.email.indexOf('.')<0 
                                            /* bugid#6695 !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z0-9_]+$/i.test(values.email)*/) {
                                        errors.email = 'Inserisci una email valida';
                                    }
                                    if(!values.password){
                                        errors.password = 'Inserisci password';
                                    }
                                    return errors;
                                }}
                                onSubmit={onSubmit}
                                >
                                {({ submitForm, isSubmitting, errors }) => (
                                    <Form noValidate autoComplete="off"> 
                                        <Field component={TextField} name="email" type="email" label="Email"/>
                                        <br /><br />

                                        <div onMouseDown={togglePassword} className="reveal-password">
                                            <div>
                                                <Visibility/>
                                            </div>
                                        </div>
                                        <Field component={TextField} type="password" label="Password" name="password" className="password" />
                                        
                                        <Box mt={2} mb={2}>
                                                {isSubmitting && <LinearProgress color="secondary" />}
                                        </Box>
                                        <Button disabled={isSubmitting} onClick={submitForm}>
                                            Login
                                        </Button>
                                    </Form>
                                )}
                                </Formik>
                            </Paper>
                            <div style={{textAlign:'center'}}>
                                <button onClick={()=>{setResetting(true)}} className="btn btn-outline" style={{marginTop:'2rem'}}>
                                    Recupero password
                                </button>
                            </div>
                        </Box>
                }

                {!isBootstrapping && isResetting &&
                    <Box m={3}>
                        <Paper elevation={3} className={classes.login}>
                            <Formik
                                initialValues={{
                                    email: ''
                                }}
                                validate={values => {
                                    const errors: Partial<ResetValues> = {};
                                    if (!values.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                                        errors.email = 'Inserisci una email valida';
                                    }
                                    return errors;
                                }}
                                onSubmit={onResetSubmit}
                                >
                                {({ submitForm, isSubmitting, errors }) => (
                                    <Form noValidate autoComplete="off"> 
                                        <Field component={TextField} name="email" type="email" label="Email"/>
                                        
                                        <Box mt={2} mb={2}>
                                                {isSubmitting && <LinearProgress color="secondary" />}
                                        </Box>
                                        <Button disabled={isSubmitting} onClick={submitForm}>
                                            Recupero password
                                        </Button>
                                    </Form>
                                )}
                                </Formik>
                            </Paper>
                            <div style={{textAlign:'center'}}>
                                <button onClick={()=>{setResetting(false)}} className="btn btn-outline" style={{marginTop:'2rem'}}>
                                    Annulla
                                </button>
                            </div>
                        </Box>
                }
                <div style={{position:'fixed',bottom:'48px'}}>
                    <pre>MODE {APP_MODE}</pre>
                    <pre>v. {version}</pre>
                </div>
            </Grid>
        </div>
    )
};

export {Login}