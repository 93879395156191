import { Button, Grid } from "@material-ui/core";
import React, { FC, Fragment, useCallback } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import { Auth } from '../../../services/auth';
import { User } from "../../../types/user";
import { Back } from "../../shared/Back";

const AppMenuChoices: FC<{}> = () => {
    let history = useHistory();
    let { path } = useRouteMatch();
    const user:User=Auth.user();
    console.log("user",user);

    const gotoMenu = useCallback(() => {
        history.go(-1);
    }, [history]);

    const logout = useCallback(() => {
        history.push(`/app/appmenu/logout`);
    }, [history]);

    const actuallyLogout = useCallback(() => {
        Auth.logout();
        let mode:string=process.env.REACT_APP_MODE;
        console.log("Zapping saved credentials (mode=" + mode + ") ...");         
        if(mode=="patient")
            localStorage.removeItem("patient-credentials");
        else if(mode=="user")
            localStorage.removeItem("credentials");
        history.replace("/");
    }, [history]);
    
    return (
        <Fragment>
            <Back goBackTo={() => {history.go(-1)}}/>            
            <Switch>
                <Route exact path={path}>
                    <Grid container direction="row" className="appmenu-grid">
                        <Button variant="contained" color="secondary" onClick={logout}>Logout</Button>
                        <i className="btn-explanation">
                            Scollega la app dalle credenziali usate e ripropone la schermata di login.
                        </i>
                    </Grid>
                </Route>
                <Route exact path={`${path}/logout`}>
                    <Grid container direction="row" className="appmenu-grid">
                        <i className="btn-explanation"><strong>Attenzione: stai eseguendo il logout</strong></i>
                        <i className="btn-explanation">Se continui ti verr&agrave; richiesta nuovamente la password di accesso</i>
                        <Button variant="contained" color="primary" onClick={gotoMenu}>Annulla</Button>
                        <Button variant="contained" color="secondary" onClick={actuallyLogout}>Esegui</Button>
                    </Grid>
                </Route>
            </Switch>
        </Fragment>
    )
}

export {AppMenuChoices}