import Axios, { AxiosRequestConfig, Canceler } from "axios";

export const BASE = process.env.REACT_APP_BASE_HOST;//"http://192.168.1.34:8081";

export const API_ROUTE = process.env.REACT_APP_API_ROUTE;

export type ApiOptions = {
    queryParams?: any;
    toastError?: boolean;
    toastMessage?: string;
    silent?: boolean;
    headers?: any;
}

const DEFAULT_OPTIONS: ApiOptions = {
    queryParams: {},
    toastError: false,
    toastMessage: "😟 E' stato rilevato un problema nel contattare il server",
    silent: false
}

const doUrl = (path: string): string => `${API_ROUTE}${path}`;

/**
 * Build an API promise and its cancelable function 
 * @param url api url
 * @param apiOpts api options
 * @param setResult callback to call when data is fetched 
 * @param isLoading callback to call to toggle loading boolean
 * @param isError callback to call to toggle error boolean
 * @returns array with first parameter the fetch promise and secondo paramter the cancelable function
 */
export const buildGetFetch = <T>(
    url: string, 
    apiOpts: ApiOptions = DEFAULT_OPTIONS, 
    setResult: (t:T) => void = () => {}, 
    isLoading: (load: boolean) => void = () => {}, 
    isError: (load: boolean) => void = () => {}): [() => Promise<T | null>, Canceler]  => {
    const {cancel, token} = Axios.CancelToken.source();
    const fetch = async () => {
        const _opts = Object.assign({}, DEFAULT_OPTIONS, apiOpts);
        const options: AxiosRequestConfig = {
            cancelToken: token,
            params: _opts.queryParams || {},
            headers: _opts.headers
        };
        return await Axios.get(doUrl(url), options)
        .then((r) => {
            return r.data;
        })
        .then((r: T) => {
            setResult(r);
            isLoading(false);
            return r;
        })
        .catch((ex) => {
            if(!Axios.isCancel(ex)){
                if(_opts.toastError && !_opts.silent){
                }
                if(!_opts.silent){
                    isError(true);
                }
                isLoading(false);
            } else {
                console.warn(`Request canceled ${ex.message}`);
            }
            return null;
        })
    };
    return [fetch, cancel];
}

/**
 * Build an API promise and its cancelable function 
 * @param url api url
 * @param apiOpts api options
 * @param setResult callback to call when data is fetched 
 * @param isLoading callback to call to toggle loading boolean
 * @param isError callback to call to toggle error boolean
 * @returns array with first parameter the fetch promise and second parameter the cancelable function
 */
export const buildPostFetch = <T>(
    url: string, 
    data: any,
    apiOpts: ApiOptions = DEFAULT_OPTIONS, 
    setResult: (t:T) => void = (t: T) => {}, 
    isLoading: (load: boolean) => void = (b: boolean) => {}, 
    isError: (load: boolean) => void = (b: boolean) => {}) : [() => Promise<T | null>, Canceler] => {
    const {cancel, token} = Axios.CancelToken.source();
    const fetch = async () => {
        const _opts = Object.assign({}, DEFAULT_OPTIONS, apiOpts);
        const options: AxiosRequestConfig = {
            cancelToken: token,
            params: _opts.queryParams || {},
            headers: _opts.headers
        };
        return await Axios.post(doUrl(url), data, options)
        .then((r) => {
            return r.data;
        })
        .then((r: T) => {
            setResult(r);
            isLoading(false);
            return r;
        })
        .catch((ex) => {
            if(!Axios.isCancel(ex)){
                if(_opts.toastError && !_opts.silent){
                }
                if(!_opts.silent){
                    isError(true);
                }
                isLoading(false);
            } else {
                console.warn(`Request canceled ${ex.message}`);
            }
            return null;
        })
    };
    return [fetch, cancel];
}

/**
 * Build an API promise and its cancelable function 
 * @param url api url
 * @param apiOpts api options
 * @param setResult callback to call when data is fetched 
 * @param isLoading callback to call to toggle loading boolean
 * @param isError callback to call to toggle error boolean
 * @returns array with first parameter the fetch promise and secondo paramter the cancelable function
 */
export const buildDeleteFetch = <T>(
    url: string, 
    apiOpts: ApiOptions = DEFAULT_OPTIONS, 
    setDone: () => void = () => {}, 
    isLoading: (load: boolean) => void = () => {}, 
    isError: (load: boolean) => void = () => {}) : [() => Promise<boolean>, Canceler] => {
    const {cancel, token} = Axios.CancelToken.source();
    const fetch = async () => {
        const _opts = Object.assign({}, DEFAULT_OPTIONS, apiOpts);
        const options: AxiosRequestConfig = {
            cancelToken: token,
            params: _opts.queryParams || {}
        };
        return await Axios.delete(doUrl(url), options)
        .then((r) => {
            return r.data;
        })
        .then((r: T) => {
            setDone();
            isLoading(false);
            return true;
        })
        .catch((ex) => {
            if(!Axios.isCancel(ex)){
                if(_opts.toastError && !_opts.silent){
                    //let msg = ex?.response?.data?.message || _opts.toastMessage;
                }
                if(!_opts.silent){
                    isError(true);
                }
                isLoading(false);
            } else {
                console.warn(`Request canceled ${ex.message}`);
            }
            return false;
        })
    };
    return [fetch, cancel];
}

/**
 * TODO 
 * catch riga 100 dovrebbe essere opzionale, potrei voler gestire il catch lato chiamata
 * da rifare meglio
 */