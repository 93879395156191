
import './App.css';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import React, { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react';
import ExamCenter from './exam/ExamCenter';
import { Paper, makeStyles, createStyles, Theme, AppBar, Button, Fab } from '@material-ui/core';
import Description from '@material-ui/icons/Description';
import { Nomatch } from '../shared/Nomatch';
import { UserCenter } from './user/UserCenter';
import { Home } from './home/Home';
import { TabContext } from './TabContext';
import { AssignmentInd, ControlPoint, Loop } from '@material-ui/icons';
import { AppMenu } from '../shared/AppMenu';
import { Auth } from '../../services/auth';
import { AppMenuChoices } from '../user/appmenu/AppMenuChoices';
import { buildGetFetch } from '../../services/base';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: '0',
      marginBottom: '4.5rem',
      border: '0',
      boxShadow: 'none'
    },
    appBar: {
      top: 'auto',
      bottom: 0,
    },
  }),
);



const App : FC<{}> = () => {

  const [value, setValue] = useState<number>(1);
  const { path } = useRouteMatch();
  const history = useHistory();
  const classes = useStyles();
  const [version, setVersion] = useState<string>();
  const [newVersionAvailable, setNewVersionAvailable] = useState<boolean>(false);
  const [consultation,setConsultation] = useState<any|null>(null);

  const onTabClick = useCallback((path: string) => {
    history.replace(path);
  }, [history]);

  useEffect(() => {
    let savedAppInfoAsString=localStorage.getItem("app-info");
    let savedAppInfo=null;
    if(savedAppInfoAsString) {
        try {
            savedAppInfo=JSON.parse(savedAppInfoAsString);
        } catch(e) {
            savedAppInfo=null;
        }
    }
    if(savedAppInfo && savedAppInfo.version) {
      console.log("savedAppInfo",savedAppInfo)
      setVersion(savedAppInfo.version);
      checkNewVersionAvailable(savedAppInfo.version);
    } else {
      setVersion("[no version]");
    }
  },[]);

  const checkNewVersionAvailable = (vv:string) => {
    if(!vv) {
      console.log("No saved version, considering no update available");
      return;
    }
    var myHeaders = new Headers();
        myHeaders.append('pragma', 'no-cache');
        myHeaders.append('cache-control', 'no-cache');
    var myInit = {
        method: 'GET',
        headers: myHeaders,
        };
    fetch('/app-info.json',myInit)
      .then((response) => response.json())
      .then((serverInfo) => {
          console.log("Saved version present, comparing versions",vv,serverInfo.version);
          if(vv!==serverInfo.version) {
              console.log("Version mismatch, an update is available....");
              setNewVersionAvailable(true);
          }
    });
  }

  const prepareWorkspace = () => {
    let patientId=Auth.user().id;
    const [fetchWorkspace] = buildGetFetch<any>(`/patient/${patientId}/consultation`);
    fetchWorkspace().then(async (res) => {
        Auth.setConsultation(res);
        setConsultation(res);
    });
};

useEffect(() => {
    prepareWorkspace();
},[]);

  const updateApp = useCallback(() => {
      window.location.href="/";
  },[]);

  const {changeTab} = useContext(TabContext);

  const goToConsultation = useCallback(() => {
    changeTab(1);    
    if(!consultation) return;
    history.push("/app/exams/" + consultation.exam.id);
  }, [changeTab,history,consultation]);

  const goToExams = useCallback(() => {
    changeTab(1);
    history.push("/app/exams");
  }, [changeTab,history]);

  const isOnBody=(window.location.href.endsWith("/body"));
  const isOnExamListOrExam=(window.location.href.indexOf("/exams")>=0);
  const isOnChat=(window.location.href.endsWith("/chat"));
  const hcss=(isOnBody)?"header-in-body":"";

  const nameSurname:string=Auth.user().name + ' ' + Auth.user().surname;
  return (
    <React.Fragment>
        <header className={hcss}>
          <AppMenu />
          <h1>DermaCo</h1>
          { nameSurname }
        </header>
        <main>
          <TabContext.Provider value={{active: value, changeTab: (newTab: number) => {
            setValue(newTab);
          }}}>
            <div className="patient container">
            { newVersionAvailable &&
              <div className="update-available" onClick={updateApp}>                
                <div><Loop fontSize="large" /></div>
                E' disponibile un aggiornamento software. Premi per aggiornare
              </div>
            }
            
              <Paper className={classes.paper}>
                <Switch>
                  <Route exact path={`${path}/`}>
                    <Home/>
                  </Route>
                  <Route path={`${path}/exams`}>
                    <ExamCenter/>
                  </Route>
                  <Route exact path={`${path}/user`}>
                    <UserCenter/>
                  </Route>
                  <Route path={`${path}/appmenu`}>
                    <AppMenuChoices />
                  </Route>
                  <Route path="*">
                    <Nomatch/>
                  </Route>
                </Switch>
              </Paper>

              { !isOnExamListOrExam && !isOnBody && !isOnChat &&
                <AppBar position="fixed" className={classes.appBar} style={{
                    backgroundColor:'transparent',
                    padding:'0.2rem',
                    boxShadow: 'none',
                    border: 0
                  }}>                  
                  { consultation && consultation.other_exams_count>0 && 
                  <div style={{textAlign:'right',paddingBottom:'8px'}}  onClick={() => onTabClick(`${path}/exams`)}>
                    <Fab variant="extended" color="primary" size="large" onClick={goToExams}>
                      <ControlPoint style={{marginRight:'4px'}} />
                      Visite
                    </Fab>
                  </div>
                  }

                  {/*
                  <Button onClick={() => {onTabClick(`${path}/exams`)}} style={{color:"white"}} className="col">
                    <Description style={{verticalAlign:'bottom'}} />Visite
              </Button>*/}
                </AppBar>
              }

            </div>
          </TabContext.Provider>
        </main>
        <footer/>
    </React.Fragment>
  );
}

export default App;

