
import { IconButton } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import React, { useCallback } from 'react';
import { Switch, Route, useHistory, useRouteMatch } from 'react-router-dom';

const AppMenu = () => {
  let history = useHistory();
  let { path } = useRouteMatch();

  const goToMenu = useCallback(() => {
    history.push(`/app/appmenu`);
  }, [history]);

  return (
    <Switch>
      <Route path={`${path}/appmenu`}>
      </Route>
      <Route path="*">
        <IconButton aria-label="menu" className="app-menu" onClick={goToMenu}>
          <MoreVert fontSize="large"/>
        </IconButton>
      </Route>
    </Switch>
  )
}

export {AppMenu};