
import './polyfills';
import './assets/css/index.css';
import './assets/css/all.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Auth } from './services/auth';
import { Noapp } from './components/shared/Noapp';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { teal, pink } from '@material-ui/core/colors';
import { APP_MODE } from './utils/versioning';
import { Main as MainUser } from './components/user/Main';
import { Main as MainPatient } from './components/patient/Main';
import { CacheLoader } from './utils/game/CacheLoader';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'OpenSans'
  },
  palette: {
    primary: teal,
    secondary: pink,
    info: {
      main: "#ffffff",
      contrastText: "#FFFFFF"
    },

  }
});


const PRECACHE_MODELS = true;

if(PRECACHE_MODELS){
  CacheLoader.preCache();
}

if (APP_MODE === "user") {
  Auth.start().then(() => {
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <MainUser />
      </ThemeProvider>,
      document.getElementById('root'));
  }).catch(() => {
    ReactDOM.render(
      <Noapp />,
      document.getElementById('root'));
  });
} else if (APP_MODE === "patient") {
  Auth.start().then(() => {
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <MainPatient />
      </ThemeProvider>,
      document.getElementById('root'));
  }).catch(() => {
    ReactDOM.render(
      <Noapp />,
      document.getElementById('root'));
  });
} else {
  console.error("INVALID MODE");
}


serviceWorker.register();
