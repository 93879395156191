
import { IconButton } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

const Back: FC<{goBackTo?: () => void}> = ({goBackTo}) => {
    
    let history = useHistory();
    const goBack = useCallback(() => {
        if(goBackTo){
            goBackTo();
        } else {
            history.goBack();
        }
    }, [goBackTo,history]);

    return (<IconButton aria-label="back" className="btn-back" onClick={goBack}>
          <ChevronLeft fontSize="large"/>
        </IconButton>)
}

export {Back};