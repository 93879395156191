
import React, { FC, Fragment, useEffect, useState } from "react";
import { Divider, Chip, Grid, Box, Typography } from "@material-ui/core";
import { Exam, ExamData } from "../../../types/exam";
import { useParams } from "react-router-dom";
import { buildGetFetch } from "../../../services/base";
import { Back } from "../../shared/Back";
import { Loader } from "../../shared/Loader";

const ExamSettings: FC<{}> = () => {

    const { examId } = useParams<{examId: any}>();
    const [loading, setLoading] = useState<boolean>(true);
    const [exam, setExam] = useState<Exam>();

    useEffect(() => {
        const [fetch, cancel] = buildGetFetch<ExamData>(`/user/exam/${examId}/`, {});
        fetch().then((data)=> {
            setLoading(false);
            if(!data){
                setExam(undefined);
            } else {
                setExam(data.exam);
            }
        })
        return () => cancel();
    }, [examId]);

    if(loading || !exam){
        return (<Loader/>)
    }

    return (
        <Fragment>
            <Back/>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" color="secondary">Richiamo</Typography>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                        <Box display="flex" flexDirection="column" alignItems="flex-start">
                            <Box color="textSecondary" component="span">Richiamo</Box>
                            {exam.reminder ? <Chip component="span" color="primary" variant="outlined" label={`${exam.reminder} mes${exam.reminder === 1 ? 'e' : 'i'}`}/> : <Typography>No</Typography>}
                        </Box>
                </Grid>
                {/*
                <Grid item xs={12}>
                    <Box display="flex" flexDirection="column">
                        <Box color="textSecondary" component="span">Visita di riferimento</Box>
                        {exam.parent_exam_id ? <Chip component="span" color="primary" label={fakeId(exam.parent_exam_id)}/> : <Typography>Nessuna</Typography>}
                    </Box>
                </Grid>
                */}
            </Grid>
        </Fragment>
    )
}


export { ExamSettings };