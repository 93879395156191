import React, { createRef } from 'react';
import { Location, History } from 'history';
import { ThreeBody, Point, ThreeEvents } from '../../../utils/game/ThreeBody';
import { buildGetFetch, buildPostFetch } from '../../../services/base';
import { ExamVisualModel } from '../../../types/examvisualmodel';
import { VisualPoint } from '../../../types/visualpoint';
import { PointCollector } from './PointCollector';
import { Backdrop, LinearProgress, Typography, Fab } from '@material-ui/core';
import { Capture } from '../capture/Capture';
import { ExamMedia } from '../../../types/exammedia';
import { ModelFactory } from '../../../utils/game/models/bodymodel';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Back } from '../Back';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { ChevronLeft, Accessibility, BurstMode } from '@material-ui/icons';
import TestWebcam from '../capture/TestWebcam';


type Props = {
  examId: number;
  /**
   * Withrouter wrapper
   */
  match: any;
  location: Location;
  history: History;
  enqueueSnackbar?: any;
};

type State = {
  uploading: boolean;
  error: boolean;
  capturing: boolean;
  showPointUi: boolean;
  showList: boolean;
  loading: boolean;
  three?: ThreeBody;
  /**
   * Three point
   */
  currentPoint: Point | null;
  /**
   * Current visual model selected
   */
  visualModelId: number | null;
  /**
   * Current visual point selected
   */
  visualPointId?: number;
  /**
   * All visual points
   */
  points: Array<VisualPoint>;
};

class BodyCollector extends React.Component<Props, State> {
  static defaultProps = {};

  private canvas = createRef<HTMLCanvasElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      error: false,
      capturing: false,
      loading: true,
      three: null,
      showPointUi: false,
      showList: false,
      currentPoint: null,
      visualModelId: null,
      uploading: false,
      points: [],
    };
    this.clean = this.clean.bind(this);
    this.reset = this.reset.bind(this);
    this.showList = this.showList.bind(this);
    this.back = this.back.bind(this);
    this.setMale = this.setMale.bind(this);
    this.setFemale = this.setFemale.bind(this);
    this.onCancelPoint = this.onCancelPoint.bind(this);
    this.onCapture = this.onCapture.bind(this);
    this.startCapture = this.startCapture.bind(this);
    this.stopCapture = this.stopCapture.bind(this);
  }

  componentDidMount() {
    const { current } = this.canvas;
    const { examId } = this.props;
    const [fetchModel] = buildGetFetch<ExamVisualModel>(`/exam/${examId}/model`);
    fetchModel().then(async (m: ExamVisualModel | null) => {
      let points: Array<VisualPoint> = [];
      if (m != null) {
        //get points for model
        const [fetchPoints] = buildGetFetch<Array<VisualPoint>>(`/exam/${examId}/model/${m.id}/point`);
        points = await fetchPoints();
      } else {
        //create a new default model
        const [createModel] = buildPostFetch<ExamVisualModel>(`/exam/${examId}/model/`, {
          /**
           * Model ID is calculated from patient genre
           */
          //model_id: 1,
          model_skin: 'Caucasico',
        });
        m = await createModel();
        if (m === null) {
          this.setState({ error: true });
          return;
        }
      }
      if (current != null) {
        const s = new ThreeBody();
        s.on(ThreeEvents.POINTADD.toString(), (p: Point) => {
          //check if p.uuid exists or not
          this.setState({
            showPointUi: true,
            currentPoint: p,
          });
          //onPointAdd(p);
        });
        s.on(ThreeEvents.POINTTOUCH.toString(), (p: Point) => {
          //check if p.uuid exists or not
          this.setState({
            showPointUi: true,
            currentPoint: p,
          });
          //onPointAdd(p);
        });
        s.on(ThreeEvents.MODELREADY.toString(), (p: Point) => {
          this.setState({
            loading: false,
          });
        });
        this.setState(
          {
            three: s,
            visualModelId: m.id,
            points: points,
          },
          () => {
            s.startGame(current, ModelFactory.createFromVisualModel(m), points);
          }
        );
      }
    });
  }

  componentWillUnmount() {
    const { three } = this.state;
    if (three !== null) {
      three.endGame();
    }
  }

  clean() {
    const { three } = this.state;
    three.resetPoints();
  }

  reset() {
    const { three } = this.state;
    three.resetPosition();
  }

  back() {
    const { history, examId } = this.props;
    if (process.env.REACT_APP_MODE === 'patient')
      history.push('/app/exams/' + examId);
    else
      history.push('/app/exam/' + examId);
  }

  showList() {
    if (this.state.showList) {
      this.setState({
        showList: false,
        showPointUi: false,
      });
    } else {
      this.setState({
        showList: true,
        showPointUi: false,
      });
    }
  }

  selectPoint(vp: VisualPoint) {
    let p: Point = {
      uuid: vp.uuid,
      x: vp.vector.x,
      y: vp.vector.y,
      z: vp.vector.z,
    };
    this.setState({
      showPointUi: true,
      currentPoint: p,
      showList: false,
    });
  }

  /**
   * @deprecated
   */
  setMale() {}

  /**
   * @deprecated
   */
  setFemale() {}

  onCancelPoint() {
    const { currentPoint, three } = this.state;
    if (currentPoint && three) {
      three.removePoint(currentPoint.uuid);
      this.setState({
        showPointUi: false,
        currentPoint: null,
      });
    }
  }

  startCapture(vp: VisualPoint) {
    // console.log(vp);
    const { currentPoint, capturing } = this.state;
    if (currentPoint && !capturing && vp.id) {
      this.setState({
        capturing: true,
        showPointUi: false,
        visualPointId: vp.id,
      });
      setTimeout(() => {
        document.getElementById("input_file_photo").click();
      },100);
    }
  }

  stopCapture() {
    this.setState({
      capturing: false,
      showPointUi: true,
    });
  }

  onCapture(img: any) {
    this.setState({
      uploading: true,
    });
    const { visualModelId, visualPointId } = this.state;
    const { examId, enqueueSnackbar } = this.props;
    const formData = new FormData();
    formData.append('image', img);
    const [createImage] = buildPostFetch<ExamMedia>(
      `/exam/${examId}/model/${visualModelId}/point/${visualPointId}/media`,
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
    );
    createImage().then((result: ExamMedia) => {
      this.setState(
        {
          uploading: false,
        },
        () => {
          if (result === null) {
            enqueueSnackbar('Errore nel caricamento immagine', { variant: 'error' });
            this.setState({
              capturing: false,
              showPointUi: false,
            });
          } else {
            enqueueSnackbar('Immagine salvata con successo', { variant: 'success' });
            this.setState({
              capturing: false,
              showPointUi: true,
            });
          }
        }
      );
    });
  }

  render() {
    const { loading, showPointUi, currentPoint, visualModelId, capturing, error, uploading } = this.state;
    const { examId } = this.props;

    const pointsDiv = this.state.points.map((p: VisualPoint) => {
      return (
        <button
          key={p.id}
          className="btn btn-info"
          style={{ display: 'block', width: '90%', marginBottom: '6px' }}
          onClick={() => {
            this.selectPoint(p);
          }}
        >
          <div style={{ fontWeight: 'bold' }}>{p.category}</div>
          <div>{p.note}</div>
        </button>
      );
    });

    if (error) {
      return (
        <Alert severity="error" variant="filled">
          <AlertTitle>Attenzione</AlertTitle>
          <Back />
          <Typography paragraph>Errore nell'inizializzare il sistema, contatta il supporto</Typography>
        </Alert>
      );
    }

    return (
      <React.Fragment>
        {loading && (
          <Backdrop className="backdrop-alert" open={true}>
            <LinearProgress color="secondary" />
            <span>Inizializzazione modello in corso</span>
          </Backdrop>
        )}
        {uploading && (
          <Backdrop className="backdrop-alert" open={true}>
            <LinearProgress color="secondary" />
            <span>Salvataggio immagine in corso</span>
          </Backdrop>
        )}
        <div>
          {/* <Capture onCapture={this.onCapture} onCancel={this.stopCapture} /> */}
          {capturing && <TestWebcam onCapture={this.onCapture} />}
          <div className="ui-container bg-theme-color1">
            <div className="ui">
              <div className="top-ui">
                <p id="message"></p>
                <Fab color="primary" onClick={this.back}>
                  <ChevronLeft />
                </Fab>
                {/*<Button className="btn" onClick={this.clean}><i className="fa fa-trash-alt"/></Button>*/}
                {/*<Fab color="primary" onClick={this.reset}>
                  <Accessibility />
                </Fab>*/}
                <Fab color="primary" style={{ float: 'right' }} onClick={this.showList}>
                  <BurstMode />
                </Fab>
              </div>
              <div className="center-ui">
                {!loading && showPointUi && (
                  <PointCollector
                    onCaptureRequest={this.startCapture}
                    examId={examId}
                    modelId={visualModelId}
                    point={currentPoint}
                    onCancel={this.onCancelPoint}
                    onClose={() => {
                      this.setState({ showPointUi: false });
                    }}
                  />
                )}
              </div>

              {!loading && this.state.showList && (
                <div className="center-ui point-list-popup">
                  <div>{pointsDiv}</div>
                </div>
              )}

              <div className="bottom-ui ui-center">
                {/* <Button className="btn" onClick={this.setMale}><i className="fa fa-mars"/></Button>
                                <Button className="btn" onClick={this.setFemale}><i className="fa fa-venus"/></Button>
                                */}
              </div>
            </div>
            <div>
              <canvas ref={this.canvas}></canvas>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
// @ts-ignore
const withR = withRouter(withSnackbar(BodyCollector));

export { withR as BodyCollector };
