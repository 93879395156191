
import { Box, Divider, Grid, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { buildGetFetch } from "../../../services/base";
import { Report } from "../../../types/report";
import { formatDT } from "../../../utils/dateutils";
import { Back } from "../../shared/Back";
import { Loader } from "../../shared/Loader";

const ExamReport: FC<{}> = () => {

    const [report, setReport] = useState<Report>();
    const [loading, setLoading] = useState<boolean>(true);    
    const { examId } = useParams<{examId: any}>();

    useEffect(() => {
        const [fetch, cancel] = buildGetFetch(`/user/exam/${examId}/report`, {}, setReport, setLoading);
        fetch();
        return () => {
            cancel();
        };
    }, [examId]);

    if(loading){
        return (<Loader/>)
    }

    return (<div>
        <Back/>
        {report ? 
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" color="secondary">Parere medico</Typography>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    Data parere {formatDT(report.release_ts)}
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" flexDirection="column">
                        <Box color="textSecondary" component="span">Parere medico</Box>
                        <Typography>{report.report}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" flexDirection="column">
                        <Box color="textSecondary" component="span">Raccomandazioni / Cura</Box>
                        <Typography>{report.recommendation}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" flexDirection="column">
                        <Box color="textSecondary" component="span">Annotazioni</Box>
                        <Typography>{report.reminder}</Typography>
                    </Box>
                </Grid>
            </Grid>
         : <Grid container color="secondary">
            <Grid item xs={12}>
            <Alert severity="warning" variant="filled" style={{width: '100%'}}>
            <AlertTitle>Attenzione</AlertTitle>
            Il parere medico non è ancora stato stilato</Alert>
            </Grid>
        </Grid>}
    </div>)
}


export {ExamReport}