
import React, { FC, Fragment, useEffect, useState } from "react";
import { Typography, Divider, Chip, Grid, Box } from "@material-ui/core";
import { ExamData } from "../../../types/exam";
import {  useParams } from "react-router-dom";
import { fakeId } from "../../../utils/faker";
import { formatDT, getAgeFromBirthDate } from "../../../utils/dateutils";
import { buildGetFetch } from "../../../services/base";
import { PatientData } from "../../../types/patient";
import { Back } from "../../shared/Back";
import { Loader } from "../../shared/Loader";

const ExamPatient: FC<{}> = () => {

    const { examId } = useParams<{examId: any}>();
    const [loading, setLoading] = useState<boolean>(true);
    const [patientData, setPatientData] = useState<PatientData>();

    useEffect(() => {
        const [fetch, cancel] = buildGetFetch<ExamData>(`/user/exam/${examId}/`, {});
        fetch().then((data)=> {
            setLoading(false);
            if(!data){
                setPatientData(undefined);
            } else {
                setPatientData(data.patientData);
            }
        })
        return () => cancel();
    }, [examId]);

    if(loading || !patientData){
        return (<Loader/>)
    }

    return (
        <Fragment>
            <Back/>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" pb={1}>
                        <Typography variant="h6" color="secondary">Paziente</Typography>
                        <Chip color="primary" variant="outlined" label={fakeId(patientData.patient.id)}/>
                    </Box>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" flexDirection="column">
                        <Box color="textSecondary" component="span">NOMINATIVO</Box>
                        <Typography variant="h6">{patientData.patient.surname} {patientData.patient.name}</Typography>
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <Box color="textSecondary" component="span">SESSO</Box>
                        <Typography variant="h6">{patientData.patient.genre}</Typography>
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <Box color="textSecondary" component="span">CODICE FISCALE</Box>
                        <Typography variant="h6">{patientData.patient.taxcode}</Typography>
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <Box color="textSecondary" component="span">DATA DI NASCITA</Box>
                        <Typography variant="h6">{formatDT(patientData.patient.date_of_birth)}</Typography>
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <Box color="textSecondary" component="span">ETA'</Box>
                        <Typography variant="h6">{getAgeFromBirthDate(patientData.patient.date_of_birth)}</Typography>
                    </Box>
                    <Divider />
                </Grid>
            </Grid>
        </Fragment>
    )
}

export { ExamPatient };