
import { BodyModel, SKIN_TYPES } from "./bodymodel";

export class Male implements BodyModel {
    name = 'male';
    file = "male.obj";
    scale = {
        x: 0.4,
        y: 0.4,
        z: 0.4
    };
    position = {
        y: -4.25
    };
    skin;

    constructor(_s: SKIN_TYPES){
        this.skin = _s;
    }
}