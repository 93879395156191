
import React, { FC, useCallback, Fragment, useState, useEffect } from "react";
import {  makeStyles, createStyles, Theme, CircularProgress } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { Exam } from "../../../types/exam";
import clsx from "clsx";
import { buildGetFetch } from "../../../services/base";
import { Back } from "../../shared/Back";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            marginTop: theme.spacing(2),
            padding: theme.spacing(1)
        }
    }),
);

const Anamnesis= () => {
    const classes = useStyles();
    const { examId } = useParams<{examId: any}>();
    const [exam, setExam] = useState<Exam>(null);
    const [editing,setEditing] = useState<boolean>(true);
    const [examsLoading,setExamsLoading] = useState<boolean>(true);

    let history = useHistory();

    const determineExam = (exams:Array<Exam>) => {
        let filteredExams:Array<Exam>=exams.filter(e => e.id==examId);
        setExam(filteredExams[0]);
    }

    useEffect(() => {
        const [fetch, cancel] = buildGetFetch<Array<Exam>>(`/exam`, {}, determineExam,setExamsLoading);
        fetch();        
        return () => {
            cancel();
        }
    }, [examId]);
    
    const goToExam = useCallback(() => {
        history.push(`/app/exam/${exam.id}`);
    }, []);

    const onFotoTYpeClick = useCallback((e: React.MouseEvent, selection: number) => {
        e.stopPropagation();
        exam.exam_medical_info.foto_type=selection;
    }, [exam]);

    if(!exam) {
        return (
            <div className="loading" style={{marginTop:'10px'}}>
                <CircularProgress color="secondary" />
            </div>
        )
    }

    return (
        <Fragment>
            <Back/>

            {exam.exam_medical_info.foto_type!=0 &&
                <div style={{marginTop:'10px'}}>
                    <div className="an_header">Fototipo</div>

                    {exam.exam_medical_info.foto_type == 1 &&
                        <div>
                            <i>FOTOTIPO 1</i><br/>
                            <div>Carnagione bianca, occhi e capelli biondi chiari o rossi, lentiggini</div>
                            <div>Non si abbronzano e si scottano sempre</div>
                            <div color="#f6d0b1" className="fototipo-es fototipo-1"></div>
                        </div>
                    }

                    {exam.exam_medical_info.foto_type == 2 &&
                        <div>
                            <i>FOTOTIPO 2</i><br/>
                            <div>Carnagione chiara, occhi chiari, capelli biondi o castani</div>
                            <div>Si abbronzano poco e con difficoltà e si scottano</div>
                            <div color="#f6d0b1" className="fototipo-es fototipo-2"></div>
                        </div>
                    }

                    {exam.exam_medical_info.foto_type == 3 &&
                        <div>
                            <i>FOTOTIPO 3</i><br/>
                            <div>Pelle abbastanza scura, occhi scuri e capelli castani</div>
                            <div>Abbronzatura e possibili ustioni solari</div>
                            <div color="#f6d0b1" className="fototipo-es fototipo-3"></div>
                        </div>
                    }

                    {exam.exam_medical_info.foto_type == 4 &&
                        <div>
                            <i>FOTOTIPO 4</i><br/>
                            <div>Carnagione particolarmente scura, occhi e capelli scuri</div>
                            <div>Abbronzatura rapida con rare scottature</div>
                            <div color="#f6d0b1" className="fototipo-es fototipo-4"></div>
                        </div>
                    }

                    {exam.exam_medical_info.foto_type == 5 &&
                        <div>
                            <i>FOTOTIPO 5/6</i><br/>
                            <div>Carnagione molto scura (etnie quali africani e indiani)</div>
                            <div>Pelle scura con rare o nessuna scottatura</div>
                            <div color="#f6d0b1" className="fototipo-es fototipo-5"></div>
                        </div>
                    }

                    {(exam.exam_medical_info.fam || exam.exam_medical_info.tumor || exam.exam_medical_info.solar_dmg || exam.exam_medical_info.foto_dmg || exam.exam_medical_info.artificial_tan || exam.exam_medical_info.big_neo ) &&
                        <div className="an_header">Altre informazioni</div>
                    }

                    {exam.exam_medical_info.fam &&
                        <div>                        
                            Ha uno o più familiari di 1° grado (genitori, fratelli o figli) con pregressa neoplasia maligna della pelle
                        </div>
                    }

                    {exam.exam_medical_info.tumor &&
                        <div>
                            Ha avuto in passato un tumore cutaneo 
                        </div>
                    }

                    {exam.exam_medical_info.solar_dmg &&
                        <div>
                            Presenta segni di pregresse ustioni solari (e presenta quindi lentiggini sulla pelle o dietro il collo)
                        </div>
                    }

                    {exam.exam_medical_info.foto_dmg &&
                        <div>
                            Presenta segni di fotodanno (presenza di campo di cancerizzazione o cheratosi attiniche)
                        </div>
                    }

                    {exam.exam_medical_info.artificial_tan &&
                        <div>
                            Si è sottoposto spesso ad abbronzatura artificiale
                        </div>
                    }

                    {exam.exam_medical_info.big_neo &&
                        <div>
                            Ha un neo congenito di grandi dimensioni
                        </div>
                    }

                    <div className="an_header">Classe di numero di nei</div>
                    {exam.exam_medical_info.neo_class==1 && <div>Minori di 50</div>}
                    {exam.exam_medical_info.neo_class==2 && <div>Maggiore di 50 e minori di 100</div>}
                    {exam.exam_medical_info.neo_class==3 && <div>Maggiori di 100 o alcuni grandi nei irregolari</div>}

                    { exam.exam_medical_info.neo_notes &&
                        <div>
                            <div className="an_header">Caratteristiche dermoscopiche di rilievo</div>
                            {exam.exam_medical_info.neo_notes}
                        </div>
                    }

                    { exam.exam_medical_info.neo_notes &&
                        <div>
                            <div className="an_header">Altre annotazioni</div>
                            {exam.exam_medical_info.generic_notes}
                        </div>
                    }
                </div>
            }

            {exam.exam_medical_info.foto_type==0 &&
                <div style={{marginTop:'10px'}}>
                    { exam.exam_medical_info.ph_time &&
                        <div>
                            <div className="an_header">Da quanto tempo sono iniziate le lesioni?</div>
                            {exam.exam_medical_info.ph_time}
                        </div>
                    }

                    { exam.exam_medical_info.ph_fixed_text &&
                        <div>
                            <div className="an_header">Restano fisse nello stesso posto o si manifestano anche in altre zone del corpo?</div>
                            {exam.exam_medical_info.ph_fixed_text}
                        </div>
                    }

                    { exam.exam_medical_info.ph_period &&
                        <div>
                            <div className="an_header">Ogni quanto si ripresentano?</div>
                            {exam.exam_medical_info.ph_period}
                        </div>
                    }

                    { exam.exam_medical_info.ph_already &&
                        <div>
                            <div className="an_header">Le è mai capitato prima?</div>
                            {exam.exam_medical_info.ph_already}
                        </div>
                    }

                    { exam.exam_medical_info.ph_other_people &&
                        <div>
                            <div className="an_header">Solo lei ne è affetto in famiglia?</div>
                            {exam.exam_medical_info.ph_other_people}
                        </div>
                    } 

                    { exam.exam_medical_info.ph_other_ph_in_family &&
                        <div>
                            <div className="an_header">Ci sono altre problematiche dermatologiche diverse da questa in famiglia?</div>
                            {exam.exam_medical_info.ph_other_ph_in_family}
                        </div>
                    }  

                    { exam.exam_medical_info.ph_drugs &&
                        <div>
                            <div className="an_header">In generale: elenco dei farmaci assunti per qualsiasi patologia presente</div>
                            {exam.exam_medical_info.ph_drugs}
                        </div>
                    }        

                    <div>
                        <div className="an_header">Dopo che è sorta la patologia oggetto del parere medico ha seguito delle terapie?</div>
                        {!exam.exam_medical_info.ph_therapies && 
                            <div>NO</div>
                        }
                        {exam.exam_medical_info.ph_therapies && 
                            <div>{exam.exam_medical_info.ph_therapies_desc}</div>
                        }
                    </div>

                    <div>
                        <div className="an_header">Prima dell'insorgere della malattia ha applicato qualche prodotto nel punto nel quale è insorta la patologia?</div>
                        {!exam.exam_medical_info.ph_products && 
                            <div>NO</div>
                        }
                        {exam.exam_medical_info.ph_products && 
                            <div>{exam.exam_medical_info.ph_products_desc}</div>
                        }
                    </div>

                    <div className="an_header">Ha dei sintomi associati quali prurito, dolore o bruciore? Se si, quanto?</div>
                    <input name="ph_symptom_degree" type="range" step="5" min="0" max="100" value={exam.exam_medical_info.ph_present} className="form-control-range" id="formControlRange"></input>

                    { exam.exam_medical_info.ph_itching_when &&
                        <div>
                            <div className="an_header">Per quanto riguarda il solo sintomo del prurito, è più presente di giorno o di notte?</div>
                            {exam.exam_medical_info.ph_itching_when}
                        </div>
                    } 

                    { exam.exam_medical_info.ph_situation &&
                        <div>
                            <div className="an_header">E' in un momento peggiore o migliore della manifestazione?</div>
                            {exam.exam_medical_info.ph_situation}
                        </div>
                    } 

                    { exam.exam_medical_info.ph_stress &&
                        <div>
                            <div className="an_header">E' in un periodo di particolare stress o ha mangiato qualcosa di diverso in questo periodo?</div>
                            {exam.exam_medical_info.ph_stress}
                        </div>
                    } 

                    <div>
                        <div className="an_header">Ha eseguito negli ultimi 6 mesi degli esami del sangue?</div>
                        {!exam.exam_medical_info.ph_blood_analysis && 
                            <div>NO</div>
                        }
                        {exam.exam_medical_info.ph_blood_analysis && 
                            <div>SI</div>
                        }
                    </div>

                    { exam.exam_medical_info.ph_generic_notes &&
                        <div>
                            <div className="an_header">Altre annotazioni</div>
                            {exam.exam_medical_info.ph_generic_notes}
                        </div>
                    }
                </div>
            }            

        </Fragment>
    )
}

export { Anamnesis };