
import React from 'react';
import {motion} from 'framer-motion';
import clsx from 'clsx';

const Button = ({children, ...rest}) => {
    return (
       <motion.button {...rest} className={clsx("btn", rest.size && `btn-${rest.size}`)} type="button" whileHover={{scale: 1.1}} whileTap={{scale: 0.9}}>{children}</motion.button> 
    );
}

export {Button};