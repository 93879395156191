
import { parse, lightFormat, parseJSON, differenceInYears, isPast } from "date-fns";

const INPUT_TS_FORMAT = "yyyy-MM-dd HH:mm:ss";

const OUTPUT_TS_FORMAT = "dd/MM/yyyy HH:mm";

// const INPUT_DT_FORMAT = "yyyy-MM-dd";

const OUTPUT_DT_FORMAT = "dd/MM/yyyy";

export const formatTS = (date: string): string => {
    if(date === null){
        return "";
    }
    return lightFormat(parse(date, INPUT_TS_FORMAT, new Date()), OUTPUT_TS_FORMAT);
}

export const parseString = (date: string): Date => {
    if(date === null){
        return new Date();
    }
    return parseJSON(date);
}

export const formatDT = (date: string): string => {
    if(date === null){
        return "";
    }
    return lightFormat(parseJSON(date), OUTPUT_DT_FORMAT);
}

export const isPastTS = (date: string): boolean => {
    if(date === null){
        return false;
    }
    return isPast(parse(date, INPUT_TS_FORMAT, new Date()));
}

export const isPastDT = (date: string): boolean => {
    if(date === null){
        return false;
    }
    return isPast(parseJSON(date));
}

export const getAgeFromBirthDate = (birth: string) => {
    return differenceInYears(new Date(), parseJSON(birth));
}