
import './Menu.css';
import React, { useCallback } from 'react';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import { Avatar } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Auth } from '../../../services/auth';

const Menu = ({onClick}) => {

    let history = useHistory();

    const onMenuClick = useCallback((path: string) => 
    (event) => {
        history.push(path);
        onClick();
    }, [history,onClick]);

    const onLogout = useCallback(() => {
        Auth.logout();
        history.replace('/');
    }, [history]);
    
    return (<div role="presentation" className="drawer">
        <div className="pad">
            <Avatar></Avatar> <span> {Auth.user().name} {Auth.user().surname}</span>
        </div>
            <Divider/>
        <List>
            <ListItem button key={'home_menu'} onClick={onMenuClick('/app')}>
                Home
            </ListItem>
            <ListItem button key={'examination_menu'} onClick={onMenuClick('/app/exam')}>
                Visita
            </ListItem>
            <Divider/>
            <ListItem button key={'logout'} onClick={onLogout}>
                Esci
            </ListItem>
        </List>
    </div>)
}

export {Menu};