
import React, { FC, useCallback, Fragment } from "react";
import { Typography, Divider, Chip, Grid, Box } from "@material-ui/core";
import { Button } from "../../shared/Button";
import { Exam } from "../../../types/exam";
import { useHistory } from "react-router-dom";
import { fakeId } from "../../../utils/faker";
import { formatDT } from "../../../utils/dateutils";

const ExamCard: FC<{ exam: Exam }> = ({ exam }) => {
    let history = useHistory();
    const goToExam = useCallback(() => {
        history.push(`/app/exam/${exam.id}`);
    }, [history,exam]);
    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" pb={1}>
                        <Typography variant="h6" color="secondary">Visita aperta</Typography>
                        <Chip color="primary" variant="outlined"  label={fakeId(exam.id)}/>
                    </Box>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" flexDirection="column">
                        {/*<Box color="textSecondary" component="span">DATA APERTURA</Box>*/}
                        <Typography variant="h6">{formatDT(exam.start_ts)}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" pb={1}>
                        <Typography variant="h6" color="secondary">Paziente</Typography>
                        <Chip color="primary" variant="outlined"  label={fakeId(exam.patient_id)}/>
                    </Box>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" flexDirection="column">
                        {/*<Box color="textSecondary" component="span">NOMINATIVO</Box>*/}
                        <Typography variant="h6">{exam.patient.surname} {exam.patient.name}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box style={{textAlign: 'center'}}>
                        <Button size="small" onClick={goToExam}>Vai alla visita&nbsp;&nbsp;<i className="fa fa-chevron-right"/></Button>
                    </Box>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export { ExamCard };