
import { useState, useEffect } from "react";
import { buildGetFetch, ApiOptions } from "../services/base";
import { NotificationService } from "../services/notificationservice";

/**
 * React custom hook for fetch
 * @param url api url to call
 * @param params optional query params
 * @returns an array with state's variables [error, loading, result]
 */
export const useGetApi = function<T>(url: string, apiOptions?: ApiOptions): [boolean, boolean, T?] {
    const [error, isError] = useState(false);
    const [loading, isLoading] = useState(true);
    const [result, setResult] = useState<T | undefined>(undefined);
    useEffect(() => {  
        const [fetch, cancel] = buildGetFetch<T>(url, apiOptions, setResult, isLoading, isError);
        fetch();
        /**
         * In the cleanup function we cancel the promise if it's still pending (so we dont trigger state changes on components that are going to be unmounted)
         */
        return () => {
            cancel('unmount cancel');
        }
    }, [url,apiOptions]);
    return [error, loading, result];
}

/**
 * Notification service hook that register and unregister a listener on NotificationService
 * @param event 
 * @param callback 
 */
export const useNotification = function<T>(event: string | symbol, callback: (event: T) => void){
    useEffect(() => {
        const listener = (event: T) => {
            callback(event);
        };
        NotificationService.on(event, listener);
        return () => {
            NotificationService.off(event, listener);
        }
    }, [event, callback]);
}