
import React from 'react';
import { BodyCollector } from './BodyCollector';
import { useParams } from 'react-router-dom';

const Body = () => {
    let { examId } = useParams<{examId: string}>();
    
    return (
        <div>
            {/* 
            // @ts-ignore */}            
            <BodyCollector examId={examId}></BodyCollector>
        </div>
    )
};


export {Body}