
import { BodyModel, SKIN_TYPES } from "./bodymodel";

export class Female implements BodyModel {
    name = "female";
    file = "female2.obj";
    scale = {
        x: 0.58,
        y: 0.58,
        z: 0.58
    };
    position = {
        y: -5,
        z: 0
    };
    rotation = {
       // y: -1.55
    }
    skin;

    constructor(_s: SKIN_TYPES){
        this.skin = _s;
    }
}