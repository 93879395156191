
import React, { FC, useCallback, useState, Fragment, useEffect } from 'react';
import { makeStyles, Theme, createStyles, TextField, Grid, Button as MButton, Typography, Fab, Box, List, ListItem, Dialog, DialogContent, Slide, Toolbar, AppBar, IconButton, Badge, Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { VisualPoint } from "../../../types/visualpoint";
import { buildPostFetch, buildGetFetch, buildDeleteFetch, BASE, API_ROUTE } from "../../../services/base";
import { Point } from '../../../utils/game/ThreeBody';
import { Loader } from '../Loader';
import AddPhotoIcon from '@material-ui/icons/AddAPhoto';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import CollectionsIcon from '@material-ui/icons/Collections';
import { TransitionProps } from '@material-ui/core/transitions';
import { Auth } from '../../../services/auth';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: theme.spacing(1),
            backgroundColor: 'rgba(255, 255, 255,0.6)',
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: '3px'
        },
        grid: {
            pointerEvents: 'all'
        },
        input: {
            pointerEvents: 'all',
            width: '100%'
        }
    })
);

type PointCollectorProps = {
    /**
     * On point cancel
     */
    onCancel: () => void;
    /**
     * On UI close
     */
    onClose: () => void;
    /**
     * On capture request
     */
    onCaptureRequest: (p: VisualPoint) => void;
    /**
     * The current three js point 
     */
    point: Point;
    /**
     * Current exam
     */
    examId: number;
    /**
     * Current model
     */
    modelId: number;
}

const PointCollector: FC<PointCollectorProps> = ({ onClose, point, examId, modelId, onCancel, onCaptureRequest }) => {
    const classes = useStyles();
    const [category, setCategory] = useState('');
    const [note, setNote] = useState('');
    const [loading, setLoading] = useState(false);
    const [showMedia, setShowMedia] = useState(false);
    const [visualPoint, setVisualPoint] = useState<VisualPoint>(null);
    const history=useHistory();    
    const [paymentActive, setPaymentActive] = useState(false);

    const isPatientMode:boolean=(process.env.REACT_APP_MODE=="patient");
    const enableAdd:boolean=(!isPatientMode || paymentActive);

    useEffect(() => {
        const [getPoint] = buildGetFetch<VisualPoint>(`/exam/${examId}/model/${modelId}/point/${point.uuid}`);
        setLoading(true);
        getPoint().then((p: VisualPoint) => {
            console.log("setting visual point",p);
            if(p) setVisualPoint(p);
            setLoading(false);
        })

        if(isPatientMode) {
            let patientId=Auth.user().id;
            const [countImageSlots] = buildGetFetch<VisualPoint>(`/user/${patientId}/countImageSlots`);
            countImageSlots().then((data: any) => {
                if(data.cnt>0) setPaymentActive(true);
                else setPaymentActive(false);
            })
        }
    }, [examId, modelId, point]);

    const onDeletePoint = useCallback((visualPoint: VisualPoint) => {
        setLoading(true);
        const [deletePoint] = buildDeleteFetch<VisualPoint>(`/exam/${examId}/model/${modelId}/point/${visualPoint.id}`, {});
        deletePoint().then(() => {
            setLoading(false);
            onCancel();
        });
    }, [examId, modelId, onCancel]);

    const showModal = () => {
        //console.log("visualPoint",visualPoint);
        if(visualPoint && visualPoint.media && visualPoint.media.length > 0){
            setShowMedia(true);
        }
    }

    const gotoPay = useCallback(() => {
        history.push("/app/exams/" + examId + "/payment");
    },[examId]);

    const onPointCreate = useCallback(() => {
        setLoading(true);
        const [createPoint] = buildPostFetch<VisualPoint>(`/exam/${examId}/model/${modelId}/point`, {
            vector: {
                x: point.x,
                y: point.y,
                z: point.z
            },
            uuid: point.uuid,
            note,
            category
        });
        createPoint().then((p: VisualPoint) => {
            if(p !== null){
                setVisualPoint(p);
            }
            setLoading(false);
        });
    }, [examId, point, note, category, modelId]);

    let content = <div />;
    if (loading) {
        content = <Loader/>
    } else if (visualPoint === null) {
        //point not existings
        content = (<Fragment>
            <Grid item xs={12}>
                <Grid container direction="column" justify="flex-end" alignItems="flex-end">
                    <MButton onClick={onCancel}><CloseIcon/></MButton>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                { enableAdd &&
                    <Grid container direction="column" justify="center" alignItems="center">
                        <Typography color="secondary">Nuovo punto</Typography>
                        <TextField label="Zona" className={classes.input} value={category} onChange={(v) => { setCategory(v.target.value); }} />
                        <TextField label="Nota opzionale" className={classes.input} value={note} onChange={(v) => { setNote(v.target.value) }} />
                        <br />
                        <Fab color="primary" onClick={onPointCreate}><SaveIcon/></Fab>
                        <br />
                    </Grid>
                }   
                { !enableAdd &&
                    <Typography color="secondary" style={{textAlign:'center'}}>
                        Per caricare nuove immagini &egrave; necessario attivare il servizio a pagamento.<br/><br/>
                        <Button variant="contained" color="secondary"  style={{marginLeft:'0'}} onClick={gotoPay}>Attiva</Button>
                    </Typography>
                }
            </Grid>
        </Fragment>);
    } else  {
        content = (<Fragment>
            <Grid item xs={12}>
                <Grid container direction="column" justify="flex-end" alignItems="flex-end">
                    <MButton style={{marginRight: 0}} onClick={onClose}><CloseIcon/></MButton>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Box mb={2} flexDirection="column">
                    <Typography color="textSecondary">Zona</Typography>
                    <Typography>{visualPoint.category}</Typography>
                </Box>
                <Box mb={2} flexDirection="column">
                    <Typography color="textSecondary">Nota</Typography>
                    <Typography>{visualPoint.note}</Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Badge overlap="circle" color="secondary" badgeContent={visualPoint.media ? visualPoint.media.length : ""}>
                        <Fab onClick={()=>{showModal()}} color="primary" title="Immagini" disabled={!visualPoint.media || visualPoint.media.length === 0}>
                            <CollectionsIcon/>
                        </Fab>
                    </Badge>
                    { enableAdd &&
                        <Fab color="primary" onClick={() => {onCaptureRequest(visualPoint)}} title="Cattura immagine"><AddPhotoIcon/></Fab>
                    }
                    { !enableAdd &&
                        <Fab color="secondary" onClick={() => {setVisualPoint(null)}} title="Cattura immagine"><AddPhotoIcon/></Fab>
                    }
                    {!isPatientMode &&
                        <Fab color="secondary" title="Elimina punto" onClick={() => {onDeletePoint(visualPoint)}}><DeleteIcon/></Fab>
                    }
                    {showMedia && 
                        <MediaList media={visualPoint.media} onClose={() => {setShowMedia(false)}}/>
                    }
                </Grid>
            </Grid>
        </Fragment>)
    }
    return (
        <div className={classes.container}>
            <Grid container className={classes.grid} direction="column">
                {content}
            </Grid>
        </div>
    )
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles2 = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            padding: 0
        },
        img: {
            width: '100%',
            borderRadius: '3px'
        },
        list: {
            padding: 0, 
            overflow: 'auto',
            paddingBottom: '1rem'
        },
        content: {
            paddingTop: '5rem'
        },
        item: {
            padding: 0,
            marginBottom: '1rem'
        }
    })
);

const MediaList: FC<{media: Array<any>, onClose?: any}> = ({media, onClose}) => {
    const classes = useStyles2();
    return (
        <Dialog fullScreen open={true} onClose={onClose} TransitionComponent={Transition}>
             <AppBar className={classes.header}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                    <CloseIcon/>
                    </IconButton>
                    <Typography>&nbsp;Immagini</Typography>
                </Toolbar>
                </AppBar>
            <DialogContent className={classes.content}>
                <List className={classes.list}>
                {media.map((m) => {
                    return (
                        <ListItem key={m.id} className={classes.item}>
                            <img alt="Immagine" src={`${BASE}${API_ROUTE}${m.path}${m.name}`}  className={classes.img}/>
                        </ListItem>
                    )
                    })}
                </List>
            </DialogContent>
        </Dialog>)}

export { PointCollector }