
import { Box, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "../../../services/auth";
import { buildGetFetch } from "../../../services/base";
import { Button } from "../../shared/Button";
import { Loader } from "../../shared/Loader";
import { TabContext } from "../TabContext";

const useStyles = makeStyles({
    media: {
      height: 80,
    },
  });


export const Home: FC<{}> = () => {

    const classes = useStyles();
    const history = useHistory();
    const {changeTab} = useContext(TabContext);
    const consultation=Auth.consultation();

    const goToExams = useCallback(() => {
        changeTab(1);
        history.push("/app/exams");
    }, [changeTab,history]);

    if(!consultation) return <Loader />;

    return (<div>
        <Grid container spacing={2}>
            <Grid item xs={12} >
                <Box textAlign="center">
                    <Typography variant="h6">Bentornato!</Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box display="flex" flexDirection="column">
                    { consultation.other_exams_count>0 && 
                        <Card style={{marginBottom:'20px'}}>
                            <CardActionArea>
                                <CardMedia image="img_visite.webp" title="" className={classes.media} />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Accedi alle tue visite
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Qui puoi trovare tutte le informazioni relative alle visite
                                        con i nostri professionisti e parlare con loro.
                                    </Typography>
                                </CardContent>                            
                            </CardActionArea> 
                            <CardActions>
                                <Button size="small" color="primary" onClick={goToExams}>
                                Accedi alle visite
                                </Button>
                            </CardActions>
                        </Card>
                    }                    
                </Box>
            </Grid>
        </Grid>
    </div>)
}